/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import * as Common from "../actions/Common.js";
import Toast from "../components/Toast.js";
import ModalConfirm from "../components/ModalConfirm.js";
import ModalCoupon from "../components/Modal_coupon.js";
import styles from "./Checkout.module.scss";

import SampleImage from "../assets/images/87302-3.png";
import arrowIcon from "../assets/icons/under.svg";
import moreIcon from "../assets/icons/depth-sm-gr.svg";
import infoIcon from "../assets/icons/interrogation-gr.svg";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const BUYING_URL = "/api/user/deal/v1/on_process";
const DEALING_URL = "/api/user/deal/v1/deal_cars";
const COUPON_URL = "/api/user/coupon/v1/me";

const Checkout = () => {
  /****************************************
   * TODO 파라미터 & 데이터
   ****************************************/
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(async () => {
    if (location.state != null) {
      fetchMyData();
      fetchDetailData(location.state.data);
      fetchCoupon();
      // console.log(location.state.data);
    }
  }, [location.state]);

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  // 부가서비스 노출
  const [addServeStatus, setAddServStatus] = useState([]);

  const handleAddServeList = (index) => {
    const result = addServeStatus.find((val) => val === index);

    if (result === undefined) {
      setAddServStatus([...addServeStatus, index]);
    } else {
      setAddServStatus(addServeStatus.filter((val) => val !== index));
    }
  };

  // 쿠폰 선택 모달
  const [couponItems, setCouponItems] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState();
  const [couponModalStatus, setCouponModalStatus] = useState(false);

  useEffect(() => {
    if (selectedCoupon !== undefined) {
      setInputs({
        ...inputs,
        ["coupon"]: selectedCoupon.coupon.price,
      });
    }
  }, [selectedCoupon]);

  //* Init API Data
  const [loading, setLoading] = useState(true);
  const [items, setItems] = useState([]);
  const [myData, setMyData] = useState([]);
  const [myPoint, setMyPoint] = useState(0);
  const [myMileage, setMyMileage] = useState(0);

  let [pointWarnStatus, setPointWarnStatus] = useState(true);
  let [pointWarnTxt, setPointWarnTxt] = useState("");

  //* 최종 구매 비용 요약
  const [totalCarPrice, setTotalCarPrice] = useState(0);
  const [totalCarComission, setTotalCarComission] = useState(0);
  const [totalAddServicesPrice, setTotalAddServicesPrice] = useState(0);
  const [paymentPrice, setPaymentPrice] = useState(0); // 결제 금액
  const [maxLimitPrice, setMaxLimitPrice] = useState(0); // 포인트 사용 제한 금액
  const [finalPaymentPrice, setFinalPaymentPrice] = useState(0); // 최종 결제 금액
  const [agreeSubmit, setAgreeSubmit] = useState(false); // 구매 동의 체크박스

  // 입력 데이터 세팅
  const [inputs, setInputs] = useState({
    point: 0, // 포인트
    mileage: 0, // 마일리지 재입력
    coupon: 0, // 쿠폰 할인
  });
  const { point, mileage, coupon } = inputs;

  const updateInputData = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: Number(value),
    });
  };

  const resetInputData = () => {
    setInputs({
      point: 0,
      mileage: 0,
      coupon: 0,
    });
  };

  useEffect(() => {
    if (items.length !== 0 && myData.length !== 0) {
      // 결제 금액
      const price =
        items.totalComissionPrice +
        items.totalAddServicePrice -
        (items.totalComissionPrice * (myData.gradeDiscountRate / 100) +
          Number(coupon));
      setPaymentPrice(price);

      // 포인트 사용 제한 금액
      const maxLimit = price * 0.2;
      setMaxLimitPrice(maxLimit);

      if (point > maxLimit) {
        // 포인트 제한 체크
        setPointWarnStatus(false);
        setPointWarnTxt(
          `결제금액의 20%(${Common.commaFormat(
            maxLimit
          )}P) 까지만 사용이 가능합니다.`
        );
      }

      // 최종 결제 금액
      setFinalPaymentPrice(price - (Number(point) + Number(mileage)));
    }
  }, [myData, items, point, mileage, coupon]);

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 토큰 상태 확인
  const fetchMyData = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setMyData(response.data.data);
      setMyPoint(response.data.data.point);
      setMyMileage(response.data.data.mileage);
      // setMyData({
      //   address: response.data.data.rentCarCorperation.address,
      //   addressDetail: response.data.data.rentCarCorperation.addressDetail,
      //   mileage: response.data.data.mileage,
      //   point: response.data.data.point,
      //   refundBankAccount:
      //     response.data.data.rentCarCorperation.refundBankAccount,
      //   refundBankName: response.data.data.rentCarCorperation.refundBankName,
      // });
      return response.data.data;
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };

  // 상품 상세
  const fetchDetailData = async (datas) => {
    setLoading(true);

    const carIds = [];
    datas.map((data) => {
      carIds.push(data.id);
    });

    try {
      const response = await axios.get(
        BASE_URL + DEALING_URL + `?dealCarIds=${carIds}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setItems(response.data.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  // 구매진행 선택
  const fetchBuying = async () => {
    const carIds = [];
    location.state.data?.map((data) => {
      carIds.push(data.id);
    });

    try {
      const response = await axios.post(
        BASE_URL + BUYING_URL,
        {
          dealCarIds: carIds,
          usingPoint: point,
          usingMileage: mileage,
          couponId: selectedCoupon?.coupon.id,
          // discountPrice: 0,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      return response.data.data.id;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 쿠폰 데이터 가져오기
  const fetchCoupon = async () => {
    try {
      const response = await axios.get(BASE_URL + COUPON_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setCouponItems(response.data.data);
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  // 포인트 전액 사용
  const handleUseMyPoint = () => {
    setInputs({
      ...inputs,
      ["point"]: myPoint,
    });
  };
  // 마일리지 전액 사용
  const handleUseMyMileage = () => {
    /*
     * 마일리지 전액 사용 조건
     * 1. 보유 마일리지(myMileage)가 0인 경우 return
     * 2. 보유 마일리지가(myMileage)가 최종 결제금액(finalPaymentPrice) 보다 높은 경우
     *  => ["mileage"]: finalPaymentPrice,
     * 3. 보유 마일리지가(myMileage)가 최종 결제금액(finalPaymentPrice) 보다 낮은 경우
     *  => ["mileage"]: myMileage,
     */

    if (myMileage === 0) {
      return;
    }
    if (myMileage >= finalPaymentPrice) {
      setInputs({
        ...inputs,
        ["mileage"]: finalPaymentPrice,
      });
    }
    if (myMileage < finalPaymentPrice) {
      setInputs({
        ...inputs,
        ["mileage"]: myMileage,
      });
    }
  };
  // 구매 진행하기
  const onSubmit = () => {
    if (finalPaymentPrice <= 0) {
      handleToast("결제 금액을 확인해주세요.");
      return;
    }
    if (pointWarnStatus === false) {
      handleToast(pointWarnTxt);
      return;
    }

    setModal({
      open: true,
      dim: false,
      title: "구매를 진행하시겠습니까?",
      message: "",
      notxt: "취소",
      oktxt: "확인",
      callback2: async () => {
        const receiptId = await fetchBuying();

        if (
          receiptId === undefined ||
          receiptId === null ||
          receiptId === false
        ) {
          return;
        }
        // 구매 진행 완료 페이지로 이동
        navigate("/receipt", {
          state: {
            receiptId: receiptId,
          },
        });
      },
    });
  };

  /****************************************
   * TODO 토스트 & 모달 상태
   ****************************************/
  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.checkout}` + " global-width"}>
          {/* 화면 좌측 - 구매 진행하기 */}
          <div className={styles.application}>
            <div className={styles.header}>
              <h1>구매 진행하기</h1>
            </div>
            <div className={styles.contents}>
              <div className={styles.itemWrapper}>
                {/* 구매 상품 정보 */}
                <div className={styles.itemCont}>
                  <div className={styles.itemHeader}>
                    <span className={styles.itemTit}>구매 상품 정보</span>
                  </div>
                  <div className={styles.detailCont}>
                    {items.dealItems?.map((item, index) => {
                      return (
                        <div className={styles.itemDetail} key={index}>
                          <div className={styles.info}>
                            <div className={styles.img}>
                              <img src={item.car.mainImgUrl} alt="" />
                            </div>
                            <div className={styles.desc}>
                              <div className={styles.title}>
                                <span>
                                  {`${item.car.manufacturerName} ${item.car.modelName}`}
                                </span>
                              </div>
                              <div className={styles.detail}>
                                <span>
                                  {String(item.car.carMadedYear).slice(2) +
                                    "년" +
                                    String(item.car.carMadedMonth) +
                                    "월 (" +
                                    String(item.car.carMadedYear).slice(2) +
                                    "년형)"}
                                </span>
                                <span>
                                  {Common.commaFormat(item.mileage)}km
                                </span>
                                <span>
                                  {item.car.fuelType === "GAS"
                                    ? "가솔린"
                                    : item.car.fuelType === "DIESEL"
                                    ? "디젤"
                                    : item.car.fuelType === "LPG"
                                    ? "LPG"
                                    : item.car.fuelType === "GAS_LPG"
                                    ? "가솔린(LPG겸용)"
                                    : item.car.fuelType === "GAS_CNG"
                                    ? "가솔린(CNG겸용)"
                                    : item.car.fuelType === "GAS_HYBRID"
                                    ? "HEV(하이브리드)"
                                    : item.car.fuelType === "ELECTRIC"
                                    ? "전기"
                                    : item.car.fuelType === "HYDROGEN"
                                    ? "수소"
                                    : null}
                                </span>
                                <span>
                                  {item.car.accident === "NONE"
                                    ? "무사고"
                                    : item.car.accident === "SIMPLE_CHANGE"
                                    ? "단순교환"
                                    : item.car.accident === "SIMPLE_TOUCH"
                                    ? "단순사고(접촉)"
                                    : item.car.accident === "NORMAL"
                                    ? "사고"
                                    : item.car.accident === "TOTAL_LOSS"
                                    ? "전손"
                                    : item.car.accident === "FLOODING"
                                    ? "침수"
                                    : null}
                                </span>
                              </div>
                              <div className={styles.options}>
                                {item.car.carOptions?.map((opt, idx) => {
                                  return (
                                    idx < 5 && (
                                      <span key={idx}>{opt.nameKr}</span>
                                    )
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                          <div className={styles.priceDetail}>
                            <div className={styles.cost}>
                              <div className={styles.item}>
                                <span className={styles.itemTit}>차량가</span>
                                <span className={styles.itemDesc}>
                                  {Common.commaFormat(item.car.carPrice.price)}
                                  원
                                </span>
                              </div>
                              <div className={styles.item}>
                                <span className={styles.itemTit}>수수료</span>
                                <span className={styles.itemDesc}>
                                  {Common.commaFormat(
                                    item.car.carPrice.comission
                                  )}
                                  원
                                </span>
                              </div>
                            </div>
                            <div
                              className={
                                addServeStatus.indexOf(index) >= 0
                                  ? `${styles.addService} ${styles.active}`
                                  : `${styles.addService}`
                              }
                            >
                              <div className={styles.listHeader}>
                                <span className={styles.tit}>부가서비스</span>
                                <button
                                  type="button"
                                  className={styles.btnListToggle}
                                  onClick={() => {
                                    handleAddServeList(index);
                                  }}
                                >
                                  <span
                                    className={styles.totalAddservicesPrice}
                                  >
                                    {/* {Common.commaFormat(
                                      items.totalAddServicePrice
                                    )} */}
                                    {Common.commaFormat(
                                      item?.addservices.reduce(
                                        (sum, currValue) => {
                                          return sum + currValue.price;
                                        },
                                        0
                                      )
                                    ) + "원"}
                                  </span>
                                  <img src={arrowIcon} alt="" />
                                </button>
                              </div>
                              <div className={styles.itemList}>
                                {item.addservices?.map((service, index) => {
                                  return (
                                    <div
                                      className={styles.item}
                                      key={service.addServiceId}
                                    >
                                      <span className={styles.itemTit}>
                                        {service.name}
                                      </span>
                                      <span className={styles.itemDesc}>
                                        {Common.commaFormat(service.price)}원
                                      </span>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                            <div className={styles.deliveryLocation}>
                              <div className={styles.item}>
                                <span className={styles.itemTit}>
                                  배송 정보
                                </span>
                                <span className={styles.itemDesc}>
                                  {item.addservices.find(
                                    (service) =>
                                      service.serviceType === "DELIVERY"
                                  ) !== undefined
                                    ? item.addservices.find(
                                        (service) =>
                                          service.serviceType === "DELIVERY"
                                      )?.deliveryAddress +
                                      " " +
                                      item.addservices.find(
                                        (service) =>
                                          service.serviceType === "DELIVERY"
                                      )?.deliveryAddressDetail
                                    : "탁송을 신청하지 않았습니다"}
                                </span>
                              </div>
                            </div>
                            <div className={styles.total}>
                              <div className={styles.item}>
                                <span className={styles.itemTit}>
                                  합산 금액 (VAT 포함)
                                </span>
                                <span className={styles.itemDesc}>
                                  {Common.commaFormat(
                                    item.car.carPrice.price +
                                      item.car.carPrice.comission +
                                      item.addservices.reduce((sum, cur) => {
                                        return sum + cur.price;
                                      }, 0)
                                  )}
                                  원
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
                {/* 쿠폰 */}
                <div className={styles.itemCont}>
                  <div className={styles.itemHeader}>
                    <span className={styles.itemTit}>쿠폰</span>
                    {/* <p className={styles.itemSubTxt}>
                      * 쿠폰은 최대 2개 적용이 가능하며, 동일한 항목은 중복
                      선택이 불가능 합니다. <br />
                      (ex. 수수료 할인 쿠폰 2가지 선택 불가)
                    </p> */}
                  </div>
                  <div className={styles.coupon}>
                    <div className={styles.couponEle}>
                      <span className={styles.eleTit}>
                        할인쿠폰{" "}
                        {couponItems.length !== 0 && `(${couponItems.length})`}
                      </span>
                      <div className={styles.couponBtns}>
                        <button
                          type="button"
                          className={
                            couponItems.length !== 0
                              ? `${styles.btnCoupon}`
                              : `${styles.btnCoupon} ${styles.disabled}`
                          }
                          onClick={() => setCouponModalStatus(true)}
                        >
                          <span>
                            {couponItems.length === 0
                              ? "보유중인 쿠폰이 없습니다."
                              : selectedCoupon === undefined
                              ? "쿠폰 선택"
                              : selectedCoupon.name}
                          </span>
                          <img src={moreIcon} alt="" />
                        </button>
                        <button
                          type="button"
                          className={
                            selectedCoupon === undefined
                              ? `${styles.btnCouponCancel} ${styles.disabled}`
                              : `${styles.btnCouponCancel}`
                          }
                          onClick={() => {
                            if (selectedCoupon !== undefined) {
                              setSelectedCoupon();
                              setInputs({
                                ...inputs,
                                ["coupon"]: 0,
                              });
                            }
                          }}
                        >
                          쿠폰 취소
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 포인트 / 마일리지 */}
                <div className={styles.itemCont}>
                  <div className={styles.itemHeader}>
                    <span className={styles.itemTit}>포인트 / 마일리지</span>
                    <p className={styles.itemSubTxt}>
                      *포인트는 1만 포인트 이상 부터 사용이 가능하며 결제금액의
                      20% 까지만 사용이 가능합니다.
                    </p>
                  </div>
                  <div className={styles.noncash}>
                    <div
                      className={
                        pointWarnStatus === true
                          ? `${styles.pointBox}` + " input-form"
                          : `${styles.pointBox}` + " input-form error"
                      }
                    >
                      <p className="form-title">
                        <span>포인트</span>
                      </p>
                      <div className="form-field">
                        <div
                          className={`${styles.pointEle}` + " input-element"}
                        >
                          <input
                            type="text"
                            name="point"
                            className=""
                            placeholder="0P"
                            onKeyDown={(e) => {
                              if (
                                !/^[0-9]+$/.test(e.key) &&
                                e.key.length === 1
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={(e) => {
                              const value = e.target.value;

                              if (point !== 0) {
                                if (
                                  pointWarnStatus === false &&
                                  value >= 10000
                                ) {
                                  setPointWarnStatus(true);
                                  setPointWarnTxt("");
                                }
                                if (value > myPoint) {
                                  setInputs({
                                    ...inputs,
                                    ["point"]: myPoint,
                                  });
                                  return;
                                }
                              } else if (point === 0) {
                                setPointWarnStatus(true);
                                setPointWarnTxt("");
                              }

                              setInputs({
                                ...inputs,
                                ["point"]: Number(value),
                              });
                            }}
                            onBlur={(e) => {
                              const value = e.target.value;

                              if (point !== 0) {
                                if (value < 10000) {
                                  setPointWarnStatus(false);
                                  setPointWarnTxt(
                                    "1만 포인트 이상 부터 사용이 가능합니다."
                                  );
                                } else {
                                  setPointWarnStatus(true);
                                  setPointWarnTxt("");
                                }

                                if (value > maxLimitPrice) {
                                  setPointWarnStatus(false);
                                  setPointWarnTxt(
                                    `결제금액의 20%(${Common.commaFormat(
                                      maxLimitPrice
                                    )}P) 까지만 사용이 가능합니다.`
                                  );
                                }
                              } else if (point === 0) {
                                setPointWarnStatus(true);
                                setPointWarnTxt("");
                              }
                            }}
                            value={point}
                          />
                        </div>
                        <button
                          type="button"
                          className="btn-noncash"
                          onClick={(e) => {
                            // 1. 포인트가 1만 이상인 경우
                            if (myPoint !== 0) {
                              if (myPoint < 10000) {
                                setPointWarnStatus(false);
                                setPointWarnTxt(
                                  "1만 포인트 이상 부터 사용이 가능합니다."
                                );
                              } else {
                                if (myPoint > maxLimitPrice) {
                                  // 보유포인트가 max보다 많은 경우 max 사용
                                  setInputs({
                                    ...inputs,
                                    ["point"]: maxLimitPrice,
                                  });
                                } else if (myPoint <= maxLimitPrice) {
                                  // 보유포인트가 max와 같거나 작은 경우 보유 포인트 사용
                                  setInputs({
                                    ...inputs,
                                    ["point"]: myPoint,
                                  });
                                }
                                setPointWarnStatus(true);
                                setPointWarnTxt("");
                              }
                            }
                          }}
                        >
                          전액 사용
                        </button>
                        <span className={styles.ncDesc}>
                          보유 : <em>{Common.commaFormat(myPoint)}P</em>
                        </span>
                      </div>
                      <p className="alert-msg error">{pointWarnTxt}</p>
                    </div>
                    <div className={`${styles.pointBox}` + " input-form"}>
                      <p className="form-title">
                        <span>마일리지</span>
                        {/* <span className={styles.infoIcon}>
                          <img src={infoIcon} alt="" />
                          <span className={styles.qDesc}>
                            마일리지에 대한 설명입니다.
                          </span>
                        </span> */}
                      </p>
                      <div className="form-field">
                        <div
                          className={`${styles.pointEle}` + " input-element"}
                        >
                          <input
                            type="text"
                            name="mileage"
                            className=""
                            placeholder="0원"
                            onKeyDown={(e) => {
                              if (
                                !/^[0-9]+$/.test(e.key) &&
                                e.key.length === 1
                              ) {
                                e.preventDefault();
                              }
                            }}
                            onChange={updateInputData}
                            value={mileage}
                          />
                        </div>
                        <button
                          type="button"
                          className="btn-noncash"
                          onClick={handleUseMyMileage}
                        >
                          전액 사용
                        </button>
                        <span className={styles.ncDesc}>
                          보유 : <em>{Common.commaFormat(myMileage)}원</em>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                {/* 포인트 혜택 */}
                <div className={styles.itemCont}>
                  <div className={styles.itemHeader}>
                    <span className={styles.itemTit}>포인트 혜택</span>
                  </div>
                  <div className={styles.point}>
                    <div className={styles.listHeader}>
                      <span className={styles.tit}>구매 적립</span>
                      <span className={styles.desc}>
                        총{" "}
                        {Common.commaFormat(
                          (items.totalComissionPrice +
                            items.totalAddServicePrice) *
                            (myData.gradeSaveRate / 100)
                        )}
                        원
                      </span>
                    </div>
                    <div className={styles.itemList}>
                      {/* <div className={styles.item}>
                        <span className={styles.itemTit}>기본 적립</span>
                        <span className={styles.itemDesc}>00,000원</span>
                      </div> */}
                      <div className={styles.item}>
                        <span className={styles.itemTit}>등급별 적립</span>
                        <span className={styles.itemDesc}>
                          {Common.commaFormat(
                            (items.totalComissionPrice +
                              items.totalAddServicePrice) *
                              (myData.gradeSaveRate / 100)
                          )}
                          원
                        </span>
                      </div>
                      {/* <div className={styles.item}>
                        <span className={styles.itemTit}>멤버십 적립</span>
                        <span className={styles.itemDesc}>00,000원</span>
                      </div> */}
                    </div>
                  </div>
                </div>
                {/* 환불 정보 */}
                <div className={styles.itemCont}>
                  <div className={styles.itemHeader}>
                    <span className={styles.itemTit}>환불 정보</span>
                    <p className={styles.itemSubTxt}>
                      *환불 계좌 정보는 ‘내 정보 → 법인정보’에서 수정이
                      가능합니다.
                    </p>
                  </div>
                  <div className={styles.itemDesc}>
                    <p className={styles.descTxt2}>
                      {myData.rentCarCorperation?.refundBankName}{" "}
                      {myData.rentCarCorperation?.refundBankAccount}{" "}
                      {myData.rentCarCorperation?.refundBankOwner}
                    </p>
                    <p className={styles.itemSubTxt}>
                      - 결제 이후 계약을 취소하실 경우 환불 처리 되며, 별도의
                      이체 수수료가 발생 될 수 있습니다.
                    </p>
                  </div>
                </div>
                {/* 구매 절차 및 필요서류 안내 */}
                <div className={styles.itemCont}>
                  <div className={styles.itemHeader}>
                    <span className={styles.itemTit}>
                      구매 절차 및 필요서류 안내
                    </span>
                  </div>
                  <div className={styles.itemNote}>
                    <div className={styles.noteDesc2}>
                      [고객]부가서비스 선택 및 구매 진행요청 →
                      [고객]'모빌리카'수수료 입금 및 부가서비스 금액 입금 →
                      [모빌리카]필요서류 안내 및 캐피탈 사 구매관련 정보 이관 →
                      [고객/양수업체]차량대금 및 이전서류 관련 작업 진행 →
                      [모빌리카]이전작업 확인 후 차량 탁송 및 출고 <br />
                      <br />
                      ※양수(고객사)사 필요서류
                      <br />
                      -사업자등록증
                      <br />
                      -보험가입필증
                      <br />
                      -매매 계약서
                      <br />
                      -양도/양수 계약서(필요 시) <br />
                      <br />
                      ※양도사 필요 서류
                      <br />
                      -자동차등록증
                      <br />
                      -인감증명서(차량 매도용)
                      <br />
                      -인감증명서(일반)
                      <br />
                      -매매 계약서
                      <br />
                      -양도/양수 계약서(필요시)
                      <br />
                      -이사회 회의록(필요시)
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* 화면 우측 - 최종 구매 비용 */}
          <div className={styles.summary}>
            <div className={styles.header}>
              <h3>결제 금액 상세</h3>
            </div>
            <div className={styles.contents}>
              <div className={styles.itemList}>
                <div className={styles.item}>
                  <span className={styles.itemTit}>차량가액</span>
                  <span className={styles.itemDesc}>
                    {Common.commaFormat(items.totalCarPrice)}원
                  </span>
                </div>
                <span className={styles.line}></span>
                <div className={styles.item}>
                  <span className={styles.itemTit}>수수료</span>
                  <span className={styles.itemDesc}>
                    {Common.commaFormat(items.totalComissionPrice)}원
                  </span>
                </div>
                <div className={styles.item}>
                  <span className={styles.itemTit}>부가서비스</span>
                  <span className={styles.itemDesc}>
                    {Common.commaFormat(items.totalAddServicePrice)}원
                  </span>
                </div>
                <div className={styles.item}>
                  <span className={styles.itemTit}>수수료 할인</span>
                  <span className={styles.itemDesc}>
                    <em>({myData.gradeDiscountRate}%)&nbsp;</em>-
                    {Common.commaFormat(
                      items.totalComissionPrice *
                        (myData.gradeDiscountRate / 100)
                    )}
                    {/* // 수수료에서 나누기 1.1  */}원
                  </span>
                </div>
                {selectedCoupon !== undefined && (
                  <div className={styles.item}>
                    <span className={styles.itemTit}>쿠폰 할인</span>
                    <span className={styles.itemDesc}>
                      -{Common.commaFormat(selectedCoupon?.coupon.price)}원
                    </span>
                  </div>
                )}
                <span className={styles.line}></span>
                <div className={styles.item}>
                  <span className={styles.itemTit}>결제 금액</span>
                  <span className={styles.itemDesc}>
                    {Common.commaFormat(paymentPrice)}원
                  </span>
                </div>
                <span className={styles.line}></span>
                <div className={styles.item}>
                  <span className={styles.itemTit}>포인트 사용</span>
                  <span className={styles.itemDesc}>
                    -{Common.commaFormat(point)}P
                  </span>
                </div>
                <div className={styles.item}>
                  <span className={styles.itemTit}>마일리지 사용</span>
                  <span className={styles.itemDesc}>
                    -{Common.commaFormat(mileage)}원
                  </span>
                </div>
              </div>
              <div className={styles.total}>
                <div className={styles.item}>
                  <span className={styles.itemTit}>
                    최종 결제금액 (VAT 포함)
                  </span>
                  <span className={styles.itemDesc}>
                    {finalPaymentPrice < 0 && "-"}
                    {Common.commaFormat(finalPaymentPrice)}원
                  </span>
                </div>
              </div>
              {/* 구매 동의 */}
              <div className={styles.agreeAll}>
                <div className={styles.sumNotice}>
                  <h6>결제 전 필수 확인 사항</h6>
                  <div className={styles.desc}>
                    <p>차량 정보 및 옵션, 최종 결제 금액을 확인해 주세요.</p>
                    <p>
                      결제금액 입금 기한은 구매 진행 요청 후 7일 이며, 7일이
                      지날 경우 자동 취소 됩니다.
                    </p>
                    <p>
                      최종 결제(입금)이 완료된 순서대로 구매 권한을 갖게 되며,
                      구매 불가 시 입금하신 금액은 전액 환불 처리 됩니다.{" "}
                    </p>
                    <p>
                      구매가 진행된 후에는 온라인 상에서 취소가 불가능하며,
                      고객센터를 통한 취소 접수만 가능합니다.
                    </p>
                    <p>
                      구매 취소 시 상품화가 진행 된 경우 이미 투입된 비용은 일부
                      공제될 수 있습니다.
                    </p>
                    <p>
                      부가서비스는 구매 진행 이후 신청/변경 하실 수
                      없습니다.(탁송 서비스는 고객센터 신청 가능)
                    </p>
                    <p>
                      부가서비스 선택 항목에 따라 작업기간이 달라질 수 있으며,
                      일부 서비스의 경우 대기가 발생 될 수 있습니다.
                    </p>
                    <p>
                      경정비, 세차 서비스는 탁송 이후 회원사가 지정한 장소에서
                      별도로 실행할 수 있습니다.
                    </p>
                    <p>차량 인수 후 단순 변심으로 인한 환불은 불가합니다.</p>
                  </div>
                </div>
                <div className={styles.itemChk}>
                  <div className={`${styles.chkItem}` + " checkbox"}>
                    <label>
                      <input
                        type="checkbox"
                        value={agreeSubmit}
                        onChange={() => setAgreeSubmit(!agreeSubmit)}
                        checked={agreeSubmit}
                      />
                      <div></div>
                      <span>위 내용에 동의합니다.</span>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className={styles.buttons}>
              <div className="button-form">
                <button
                  type="button"
                  className={
                    agreeSubmit === true
                      ? "btn btn-xl btn--primary"
                      : "btn btn-xl btn--disabled"
                  }
                  // className="btn btn-xl btn--primary"
                  onClick={onSubmit}
                >
                  구매 진행하기
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}

      {/* (새창) 쿠폰선택 모달 */}
      {couponModalStatus ? (
        <ModalCoupon
          deleter={setCouponModalStatus}
          selectedCoupon={selectedCoupon}
          setSelectedCoupon={setSelectedCoupon}
        />
      ) : null}
    </>
  );
};

export default Checkout;
