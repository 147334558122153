/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import doneIcon from "../assets/icons/done-pr.svg";

import * as Common from "../actions/Common.js";
import Toast from "../components/Toast.js";
import ModalConfirm from "../components/ModalConfirm.js";
import styles from "./Receipt.module.scss";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const DEALFINAL_URL = "/api/user/deal/v1/deal_final";

const Receipt = () => {
  const navigate = useNavigate();

  /****************************************
   * TODO 파라미터 & 데이터
   ****************************************/
  //* Init API Data
  const [loading, setLoading] = useState(true);
  const location = useLocation();
  const [receiptId, setReceiptId] = useState([]);
  const [myData, setMyData] = useState([]);
  const [receiptData, setReceiptData] = useState([]);
  const [dealItems, setDealItems] = useState([]);
  const [locations, setLocations] = useState([]);
  const [locationStatus, setLocationStatus] = useState(true); // 화면 중복 유효성 체킹용

  useEffect(async () => {
    if (location.state != null) {
      setReceiptId(location.state.receiptId);
      fetchMyData();
      fetchDealData(location.state.receiptId);
    }
  }, [location.state]);

  useEffect(() => {
    dealItems.map((item) => {
      item.addservices.map((service) => {
        if (service.serviceType === "DELIVERY") {
          setLocations((prev) => [...prev, service.deliveryAddress]);
        }
      });
    });
  }, [dealItems]);
  useEffect(() => {
    // console.log(locations);
    if (locations.length !== 0 && locationStatus === true) {
      // console.log(allEqual(locations));
      setLocationStatus(false);
    }
  }, [locations]);

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 토큰 상태 확인
  const fetchMyData = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setMyData(response.data.data);
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };
  // 구매내역 상세
  const fetchDealData = async (receiptId) => {
    setLoading(true);
    try {
      const response = await axios.get(
        BASE_URL + DEALFINAL_URL + `/${receiptId}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      setReceiptData(response.data.data);
      setDealItems(response.data.data.dealItems);
      setLoading(false);
    } catch (err) {
      handleToast(err.response?.data.message);
      setLoading(false);
    }
  };

  /****************************************
   * TODO 공용 함수
   ****************************************/
  // 배열 내 같은 값 있는지 확인
  const allEqual = (arr) => {
    // 주소지 배열 내 모든 값이 같은 경우 true, 하나라도 틀리면 false
    return arr.every((v) => v === arr[0]);
  };
  /****************************************
   * TODO 토스트 & 모달 상태
   ****************************************/
  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.receipt}` + " global-width"}>
          <div className={styles.logo}>
            <img src={doneIcon} alt="" />
          </div>
          <div className={styles.mainTxt}>구매를 완료했습니다.</div>
          <p className={styles.subTxt}>
            아래 계좌정보로 입금이 완료되면 상품화가 진행됩니다.
          </p>

          <div className={styles.form}>
            {/* 입금 계좌 안내 */}
            <div className={styles.itemCont}>
              <div className={styles.itemHeader}>
                <span className={styles.tit}>입금 계좌 안내</span>
              </div>
              <div className={styles.itemBody}>
                <div className={`${styles.itemList} ${styles.bgGray}`}>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>계좌 정보</div>
                    <div className={styles.itemDesc}>
                      {receiptData.bankName + " " + receiptData.bankAccount}
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>예금자명</div>
                    <div className={styles.itemDesc}>
                      {receiptData.bankOwner}
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>결제 금액</div>
                    <div className={styles.itemDesc}>
                      <em>
                        {Common.commaFormat(
                          receiptData.dealFinal?.totalDepositPrice
                        )}
                      </em>
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>입금 기간</div>
                    <div className={styles.itemDesc}>
                      {receiptData.dealFinal?.depositByDate
                        ?.split("T")[0]
                        .replaceAll("-", ".")}
                      까지
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* 구매 상세 정보 */}
            <div className={styles.itemCont}>
              <div className={styles.itemHeader}>
                <span className={styles.tit}>구매 상세 정보</span>
              </div>
              <div className={styles.itemBody}>
                <div className={styles.itemTitList}>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>구매 날짜</div>
                    <div className={styles.itemDesc}>
                      {receiptData.dealFinal?.createdAt
                        ?.split("T")[0]
                        .replaceAll("-", ".")}
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>구매번호</div>
                    <div className={styles.itemDesc}>
                      {receiptData.dealFinal?.identityNumber}
                    </div>
                  </div>
                </div>
                <div className={styles.itemList}>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>총 구매 차량</div>
                    <div className={styles.itemDesc}>
                      {receiptData.dealItems?.length}대
                    </div>
                  </div>
                  <span className={styles.line}></span>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>
                      <em>총 구매 비용</em> <br />
                      <span className={styles.subTxt}>
                        (차량 가액 + 모빌리카 서비스 비용)
                      </span>
                    </div>
                    <div className={styles.itemDesc}>
                      <em>
                        {Common.commaFormat(
                          receiptData.dealFinal?.totalCarPrice +
                            receiptData.dealFinal?.totalComissionPrice +
                            receiptData.dealFinal?.totalAddServicePrice -
                            (receiptData.dealFinal?.gradeDiscountPrice +
                              receiptData.dealFinal?.couponDiscountPrice)
                        )}
                        {/* {Common.commaFormat(receiptData.dealFinal?.totalCarPrice)} */}
                        원
                      </em>
                    </div>
                  </div>
                  <span className={styles.line}></span>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>차량 가액</div>
                    <div className={styles.itemDesc}>
                      {Common.commaFormat(receiptData.dealFinal?.totalCarPrice)}
                      원
                    </div>
                  </div>
                  <span className={styles.line}></span>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>
                      <em>모빌리카 서비스</em>
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>수수료</div>
                    <div className={styles.itemDesc}>
                      {Common.commaFormat(
                        receiptData.dealFinal?.totalComissionPrice
                      )}
                      원
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>부가서비스 이용</div>
                    <div className={styles.itemDesc}>
                      {Common.commaFormat(
                        receiptData.dealFinal?.totalAddServicePrice
                      )}
                      원
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>수수료 할인</div>
                    <div className={styles.itemDesc}>
                      ({receiptData.dealFinal?.gradeDiscountRate}%) -
                      {Common.commaFormat(
                        receiptData.dealFinal?.gradeDiscountPrice
                      )}
                      원
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>쿠폰 할인</div>
                    <div className={styles.itemDesc}>
                      -
                      {Common.commaFormat(
                        receiptData.dealFinal?.couponDiscountPrice
                      )}
                      원
                    </div>
                  </div>
                  <span className={styles.line}></span>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>
                      <em>서비스 비용 합계</em>
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>포인트</div>
                    <div className={styles.itemDesc}>
                      -
                      {Common.commaFormat(
                        receiptData.dealFinal?.totalUsedPoint
                      )}
                      P
                    </div>
                  </div>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>마일리지</div>
                    <div className={styles.itemDesc}>
                      -
                      {Common.commaFormat(
                        receiptData.dealFinal?.totalUsedMileage
                      )}
                      원
                    </div>
                  </div>
                </div>
                <div className={styles.totalPrice}>
                  <div className={styles.itemTit}>최종 구매 비용</div>
                  <div className={styles.itemDesc}>
                    {Common.commaFormat(
                      receiptData.dealFinal?.totalDepositPrice
                    )}
                    원
                  </div>
                </div>
              </div>
            </div>
            {/* 포인트 혜택 */}
            <div className={`${styles.itemCont} ${styles.pointItem}`}>
              <div className={styles.itemHeader}>
                <span className={styles.tit}>포인트 혜택</span>
              </div>
              <div className={styles.itemBody}>
                <div className={styles.itemTitList}>
                  <div className={styles.item}>
                    <div className={styles.itemTit}>구매 적립</div>
                    <div className={styles.itemDesc}>
                      총{" "}
                      {Common.commaFormat(
                        receiptData.dealFinal?.gradeSavePrice
                      )}
                      원
                    </div>
                  </div>
                </div>
                <div className={styles.itemList}>
                  {/* <div className={styles.item}>
                  <div className={styles.itemTit}>기본 적립</div>
                  <div className={styles.itemDesc}>000,000원</div>
                </div> */}
                  <div className={styles.item}>
                    <div className={styles.itemTit}>등급별 적립</div>
                    <div className={styles.itemDesc}>
                      {Common.commaFormat(
                        receiptData.dealFinal?.gradeSavePrice
                      )}
                      원
                    </div>
                  </div>
                  {/* <div className={styles.item}>
                  <div className={styles.itemTit}>멤버십 적립</div>
                  <div className={styles.itemDesc}>000,000원</div>
                </div> */}
                </div>
              </div>
            </div>
            {/* 배송 정보 */}
            <div className={styles.itemCont}>
              <div className={styles.itemHeader}>
                <span className={styles.tit}>배송 정보</span>
              </div>
              <div className={styles.itemBody}>
                <p className={styles.itemDesc}>
                  {locations.length === 0 &&
                  <span>배송지 정보가 없습니다.</span>
                  }
                  {allEqual(locations) ? (
                    locations[0]
                  ) : (
                    <>
                      <span>차랑별 배송주소가 상이 합니다.</span>
                      <span>구매완료페이지 구매내역에서 확인 가능합니다.</span>
                    </>
                  )}
                </p>
              </div>
            </div>
            {/* 환불 정보 */}
            <div className={styles.itemCont}>
              <div className={styles.itemHeader}>
                <span className={styles.tit}>환불 정보</span>
              </div>
              <div className={styles.itemBody}>
                <p className={styles.itemDesc}>
                  {receiptData.refundBankName +
                    " " +
                    receiptData.refundBankAccount +
                    " " +
                    receiptData.refundBankOwner}
                </p>
              </div>
            </div>
            {/* 버튼 */}
            <div className={`${styles.buttons}` + " button-form"}>
              <button
                type="button"
                className="btn btn-xl btn--primary"
                onClick={() => {
                  navigate("/buy");
                }}
              >
                다른 차량 구매하기
              </button>
              <button
                type="button"
                className="btn btn-xl btn--outline_gray"
                onClick={() => {
                  navigate("/mypage", {
                    state: {
                      tabIndex: 3,
                    },
                  });
                }}
              >
                결제내역 보기
              </button>
            </div>
          </div>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default Receipt;
