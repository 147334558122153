/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Toast from "../components/Toast.js";
import * as Common from "../actions/Common.js";


import styles from "./Share.module.scss";
import closeIcon from "../assets/icons/close.svg";
import kakaoIcon from "../assets/icons/button-kakao.svg";
import linkIcon from "../assets/icons/button-link.svg";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const LINK_URL = "/api/user/link/v1/car_detail";
const { Kakao } = window;

const Share = ({ dim, setModal, carId, carData }) => {
    const [url, setUrl] = useState();

    useEffect(() => {
        setUrl(`https://chadapick.com/product/${carId}`);
        // setUrl(window.location.href);

        if (!Kakao.isInitialized()) {
            Kakao.init("4f6e67ffc72074a283e43a84bd9b52ea");
        }
    }, [])

    const handleCancel = () => {
        setModal(false);
    };

    const handleCopyClipBoard = async () => {
        try {
            await navigator.clipboard.writeText(url);
            handleToast("링크가 복사되었습니다.");
        } catch (error) {
        }
    };

    // 카카오톡 공유 버튼 클릭
    const fetchKakaoShare = async () => {
        try {
            const response = await axios.get(
                BASE_URL + LINK_URL + `/${carId}`
            );

            sendKakao(response.data.data); // response link
        } catch (err) {
            handleToast(err.response?.data.message);
        }
    };

    const sendKakao = (url) => {
        const carMadeYear = `${String(carData.carMadedYear).slice(2) +
            "." +
            String(carData.carMadedMonth) +
            " (" +
            String(carData.carMadedYear).slice(2) +
            ")년형"
            }`;
        const mileage = `${carData.mileage && (
            Common.commaFormat(carData.mileage)
        )}km`;

        const fuelType = `${carData.fuelType && (
            carData.fuelType == "GAS"
                ? "가솔린"
                : carData.fuelType == "DIESEL"
                    ? "디젤"
                    : carData.fuelType == "LPG"
                        ? "LPG"
                        : carData.fuelType == "GAS_LPG"
                            ? "가솔린(LPG겸용)"
                            : carData.fuelType == "GAS_CNG"
                                ? "가솔린(CNG겸용)"
                                : carData.fuelType == "GAS_HYBRID"
                                    ? "HEV(하이브리드)"
                                    : carData.fuelType == "ELECTRIC"
                                        ? "전기"
                                        : carData.fuelType == "HYDROGEN"
                                            ? "수소"
                                            : null)
            }`;

        Kakao.Link.sendDefault({
            objectType: 'feed',
            content: {
                title: `모빌리카 [${carData.carNumber}] 차량 공유!`,
                description: `${carMadeYear} · ${mileage} · ${fuelType}`,
                imageUrl: `${carData.mainImgUrl}`,
                link: {
                    webUrl: `https://chadapick.com/product/${carId}`,
                    mobileWebUrl: url,
                },
            },
            social: {
                viewCount: carData.viewCnt ?? 0,
                likeCount: carData.favoriteCnt ?? 0,
            },
            buttons: [
                {
                    title: '바로확인하기',
                    link: {
                        webUrl: `https://chadapick.com/product/${carId}`,
                        mobileWebUrl: url,
                    },
                },
            ],
        });

        handleCancel();
    };

    // FeedTemplate(
    //     content: Content(
    //         title: "모빌리카 [${car.carNumber}] 차량 공유!",
    //       imageUrl: Uri.parse("${car.mainImgUrl}"),
    //       description: getCarDescription(car),
    //       link: Link(
    //         mobileWebUrl: Uri.parse(mobileUrl), // 위 API 에서 response link
    //         webUrl: Uri.parse(pcUrl) // https://chadapick.com/product/{carId} format
    //       ),
    //     ),
    //    social: Social(
    //      viewCount: (car.viewCnt ?? 0).toInt(),
    //      likeCount: (car.favoriteCnt ?? 0).toInt(),
    //    ),
    //   buttons: [
    //     Button(
    //         title: "바로확인하기",
    //         link: Link(
    //           mobileWebUrl: Uri.parse(mobileUrl), https://developers.kakao.com/docs/latest/ko/message/js
    //           webUrl: Uri.parse(pcUrl) // https://chadapick.com/product/{carId} format
    //       ),
    //         )
    //     )
    //   ],
    // );

    //* 토스트 팝업 세팅
    const [toastStatus, setToastStatus] = useState(false);
    const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

    const handleToast = (txt) => {
        setToastStatus(true);
        setToastMsg(txt);
    };
    useEffect(() => {
        if (toastStatus) {
            setTimeout(() => {
                setToastStatus(false);
                setToastMsg("");
            }, 1000);
        }
    }, [toastStatus]);

    return (
        <>
            <div className={dim ? styles.wrapper + " " + styles.dim : styles.wrapper}>

                <div className={styles.share}>
                    <button className={styles.btnClose} type="button" onClick={handleCancel}>
                        <img src={closeIcon} alt="" />
                    </button>

                    <div className={styles.tit}>
                        <span>공유하기</span>
                    </div>

                    <div className={styles.itemList}>
                        <div className={styles.item} onClick={fetchKakaoShare}>
                            <div className={styles.img}>
                                <img src={kakaoIcon} alt="" />
                            </div>
                            <div className={styles.txt}>
                                <span>카카오톡</span>
                            </div>
                        </div>
                        <div className={styles.item} onClick={handleCopyClipBoard}>
                            <div className={styles.img}>
                                <img src={linkIcon} alt="" />
                            </div>
                            <div className={styles.txt}>
                                <span>URL 복사</span>
                            </div>
                        </div>
                    </div>

                    <div className={styles.urlShare}>
                        <div className={styles.url} onClick={handleCopyClipBoard}>
                            <input type="text" readOnly value={url || ""} />
                        </div>
                        <button type="button" onClick={handleCopyClipBoard}>복사</button>
                    </div>
                </div >
            </div>

            {/* 토스트 팝업 */}
            <Toast visible={toastStatus} msg={toastMsg} />
        </>
    )
}

export default Share