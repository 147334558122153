/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";

import * as Common from "../actions/Common.js";
import Toast from "../components/Toast.js";
import ModalConfirm from "../components/ModalConfirm.js";
import DaumPost from "../components/DaumPost.js";
import styles from "./Service.module.scss";
import { AiOutlineConsoleSql } from "react-icons/ai";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const SERVICE_URL = "/api/user/add_service/v1";
const DELIVERY_URL = "/api/user/member/v1/delivery_location";
const ADDSERVICE_URL = "/api/user/deal/v1/add_service";
const PRODUCT_URL = "/api/user/product/v1";

const Service = () => {
  /****************************************
   * TODO 파라미터 & 데이터
   ****************************************/
  const navigate = useNavigate();
  const location = useLocation();
  const [isLocationReady, setIsLocationReady] = useState(false);
  const [carDatas, setCarDatas] = useState();

  const setServices = (services) => {
    let arr1 = null,
      arr2 = [],
      arr3 = null,
      arr4 = [],
      arr5 = null,
      arr6 = [],
      arr7 = [],
      arr8 = null;

    services.map((service) => {
      let serviceType = service.serviceType;
      let value = service.name;

      switch (serviceType) {
        case "FINANCIAL":
          arr1 = value;
          break;
        case "SUNTING":
          arr2.push(value);
          break;
        case "BLACKBOX":
          arr3 = value;
          break;
        case "ORGANIZATION":
          arr4.push(value);
          break;
        case "REPIAR":
          arr5 = value;
          break;
        case "CLEANING":
          arr6.push(value);
          break;
        case "DETAILING":
          arr7.push(value);
          break;
        case "DELIVERY":
          arr8 = value;
          break;
      }
    });

    setChkItems1(arr1);
    setChkItems2(arr2);
    setChkItems3(arr3);
    setChkItems4(arr4);
    setChkItems5(arr5);
    setChkItems6(arr6);
    setChkItems7(arr7);
    setChkItems8(arr8);
    setTimeout(() => {
      setSelectedItems(services);
    }, 100);
  };

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  const [carItem, setCarItem] = useState([]);
  const [items, setItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [deliveryLocation, setDeliveryLocation] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(async () => {
    //! deliveryLocation.length가 0인 사람은 setCarDatas가 안됨
    //! useEffect deliveryLocation을 넣은 이유는 deliveryLocation가 존재하는 경우 parameter 매칭시키기 위해서
    // console.log(location.state.data);
    if (isLocationReady === true) {
      if (location.state != null && deliveryLocation.length !== 0) {
        setCarDatas(location.state.data);
        setServices(location.state.data[0].addServices);
        setAddressService(location.state.data[0].addServices);
      } else if (location.state != null && deliveryLocation.length === 0) {
        setCarDatas(location.state.data);
        setServices(location.state.data[0].addServices);
        setAddressService(location.state.data[0].addServices);
      }
    }
  }, [location.state, deliveryLocation, isLocationReady]);

  const fetchData = async () => {
    try {
      //* 부가서비스 데이터 세팅
      fetchService();
      fetchDelivery();
    } catch (error) {}
  };

  const [financialStatus, setFinancialStatus] = useState(false);
  const [suntingStatus, setSuntingStatus] = useState(false);
  const [blackboxStatus, setBlackboxStatus] = useState(false);
  const [organizationStatus, setOrganizationStatus] = useState(false);
  const [repiarStatus, setRepiarStatus] = useState(false);
  const [cleaningStatus, setCleaningStatus] = useState(false);
  const [detailingStatus, setDetailingStatus] = useState(false);
  const [deliveryStatus, setDeliveryStatus] = useState(false);

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 부가서비스 목록 가져오기
  const fetchService = async () => {
    setLoading(true);

    try {
      const response = await axios.get(
        BASE_URL + SERVICE_URL + `?car_id=${location.state.data[0].car.id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      const result = response.data.data;
      setItems(result);

      result.find((item) => {
        if (item.serviceType === "FINANCIAL") {
          setFinancialStatus(true);
        }
      });
      result.find((item) => {
        if (item.serviceType === "SUNTING") {
          setSuntingStatus(true);
        }
      });
      result.find((item) => {
        if (item.serviceType === "BLACKBOX") {
          setBlackboxStatus(true);
        }
      });
      result.find((item) => {
        if (item.serviceType === "ORGANIZATION") {
          setOrganizationStatus(true);
        }
      });
      result.find((item) => {
        if (item.serviceType === "REPIAR") {
          setRepiarStatus(true);
        }
      });
      result.find((item) => {
        if (item.serviceType === "CLEANING") {
          setCleaningStatus(true);
        }
      });
      result.find((item) => {
        if (item.serviceType === "DETAILING") {
          setDetailingStatus(true);
        }
      });
      result.find((item) => {
        if (item.serviceType === "DELIVERY") {
          setDeliveryStatus(true);
        }
      });

      setLoading(false);
    } catch (err) {
      handleToast(err.response?.data.message);
      setLoading(false);
    }
  };
  // 배송지 목록 가져오기
  const fetchDelivery = async () => {
    try {
      const response = await axios.get(BASE_URL + DELIVERY_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setDeliveryLocation(response.data.data);
      setIsLocationReady(true);

      if (location.state === null) {
        setSelectedAddress({
          address: response.data.data[0].address,
          addressDetail: response.data.data[0].addressDetail,
          sido: response.data.data[0].sido,
          sigungu: response.data.data[0].sigungu,
          sigunguCode: response.data.data[0].sigunguCode,
        });
      }
    } catch (err) {
      // handleToast(err.response?.data.message);
    }
  };
  // 부가서비스 선택 완료
  const fetchAddService = async () => {
    try {
      const serviceIds = selectedItems.map((value) => {
        return value.id;
      });

      const data = carDatas.map((carData) => {
        return {
          dealCarId: carData.id,
          addServices: selectedItems.map((item) => {
            if (item.serviceType === "DELIVERY") {
              if (item.id !== undefined) {
                return {
                  addServiceId: item.id,
                  deliveryLocation: {
                    name: addressName,
                    address: selectedAddress.address,
                    addressDetail: selectedAddress.addressDetail,
                    sido: selectedAddress.sido,
                    sigungu: selectedAddress.sigungu,
                    sigunguCode: selectedAddress.sigunguCode,
                    saveYn: newAddChk ? "Y" : "N",
                  },
                };
              } else if (item.addServiceId !== undefined) {
                return {
                  addServiceId: item.addServiceId,
                  deliveryLocation: {
                    name: addressName,
                    address: selectedAddress.address,
                    addressDetail: selectedAddress.addressDetail,
                    sido: selectedAddress.sido,
                    sigungu: selectedAddress.sigungu,
                    sigunguCode: selectedAddress.sigunguCode,
                    saveYn: newAddChk ? "Y" : "N",
                  },
                };
              }
            } else {
              if (item.id !== undefined) {
                return {
                  addServiceId: item.id,
                };
              } else if (item.addServiceId !== undefined) {
                return {
                  addServiceId: item.addServiceId,
                };
              }
            }
          }),
        };
      });

      const response = await axios.post(
        BASE_URL + ADDSERVICE_URL,
        {
          items: data,
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        }
      );

      return response;
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };
  // 상품 상세
  const fetchDetailData = async (id) => {
    try {
      const response = await axios.get(BASE_URL + PRODUCT_URL + `/${id}`);

      setCarItem(response.data.data); // 아이템 상세 정보
      return response;
    } catch (error) {
      console.log(error);
    }
  };

  /****************************************
   * TODO 이벤트 핸들러
   ****************************************/
  //* 부가서비스 선택
  // const [chkItems, setChkItems] = useState([]);
  const [chkItems1, setChkItems1] = useState([]); // 금융서비스
  const [chkItems2, setChkItems2] = useState([]); // 썬팅
  const [chkItems3, setChkItems3] = useState([]); // 블랙박스
  const [chkItems4, setChkItems4] = useState([]); // 경정비
  const [chkItems5, setChkItems5] = useState([]); // 파손수리
  const [chkItems6, setChkItems6] = useState([]); // 세차
  const [chkItems7, setChkItems7] = useState([]); // 디테일링
  const [chkItems8, setChkItems8] = useState([]); // 탁송

  const chkHandler = (e) => {
    let checked = e.currentTarget.checked;
    let value = e.target.value;
    let serviceType = e.currentTarget.name;

    switch (serviceType) {
      case "FINANCIAL":
        if (checked) {
          setChkItems1(value);
        } else {
          setChkItems1();
        }
        break;
      case "SUNTING":
        if (checked) {
          setChkItems2([...chkItems2, value]);
        } else {
          setChkItems2(chkItems2.filter((o) => o !== value));
        }
        break;
      case "BLACKBOX":
        if (checked) {
          setChkItems3(value);
        } else {
          setChkItems3();
        }
        break;
      case "ORGANIZATION":
        if (checked) {
          setChkItems4([...chkItems4, value]);
        } else {
          setChkItems4(chkItems4.filter((o) => o !== value));
        }
        break;
      case "REPIAR":
        if (checked) {
          setChkItems5(value);
        } else {
          setChkItems5();
        }
        break;
      case "CLEANING":
        if (checked) {
          setChkItems6([...chkItems6, value]);
        } else {
          setChkItems6(chkItems6.filter((o) => o !== value));
        }
        break;
      case "DETAILING":
        if (checked) {
          setChkItems7([...chkItems7, value]);
        } else {
          setChkItems7(chkItems7.filter((o) => o !== value));
        }
        break;
      case "DELIVERY":
        if (checked) {
          setChkItems8(value);
        } else {
          setChkItems8();
        }
        break;
    }

    const arr = [];
    const result = items.filter((val) => {
      return val.serviceType === serviceType;
    });
    result.map((res) => {
      arr.push(res.name);
    });
    // const filtered = arr.filter((el) => el !== value);
    // const arr2 = chkItems.filter((item) => !filtered.includes(item));

    // if (checked) {
    //   setChkItems([...chkItems, value]);
    // } else if (!checked && chkItems.includes(value)) {
    //   setChkItems(chkItems.filter((el) => el !== value));
    // }

    if (serviceType == "DELIVERY" && checked) {
      setConsignmentStatus(true);
      // fetchDelivery();
    } else if (serviceType == "DELIVERY" && !checked) {
      setConsignmentStatus(false);
    }
  };

  useEffect(() => {
    const allChkItems = [
      // chkItems,
      chkItems1,
      chkItems2,
      chkItems3,
      chkItems4,
      // chkItems5,
      chkItems6,
      chkItems7,
      chkItems8,
    ];
    // const result = items.filter((item) => allChkItems.includes(item.name));
    //! items(services) 배열에서, checked items(selected services)의 name과 일치한 것이 있는지 체킹,
    const result = items.filter((item) =>
      allChkItems.flat().includes(item.name)
    );
    setSelectedItems(result);
  }, [
    // chkItems,
    chkItems1, // 금융서비스
    chkItems2, // 썬팅
    chkItems3, // 블랙박스
    chkItems4, // 경정비
    // chkItems5, // 파손수리
    chkItems6, // 세차
    chkItems7, // 디테일링
    chkItems8, // 탁송
  ]);

  //* 배송지 선택
  const [selectedAddress, setSelectedAddress] = useState({
    address: "",
    addressDetail: "",
    sido: "",
    sigungu: "",
    sigunguCode: "",
  });

  // 탁송 UI 상태
  const [consignmentStatus, setConsignmentStatus] = useState(false);
  // 탁송 기존 배송지/신규 배송지 탭 인덱스
  const [addressIndex, setAddressIndex] = useState(0);
  // 신규 배송지로 등록 체크박스
  const [newAddChk, setNewAddChk] = useState(false);

  // 주소 검색 팝업 데이터
  const [inputs, setInputs] = useState({
    address: "", // 사업자 주소
    addressDetail: "", // 사업자 주소(상세)
    addressName: "", // 사업자 이름
  });
  const { address, addressDetail, addressName } = inputs;

  const resetInputData = () => {
    setInputs({
      address: "",
      addressDetail: "",
    });
  };

  const updateInputData = (e) => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });

    if (name == "addressDetail") {
      setSelectedAddress((prev) => ({
        ...prev,
        addressDetail: value,
      }));
    }
  };

  // 선택 완료, 마이페이지로 복귀
  const onComplete = () => {
    if (selectedItems.length === 0) {
      setModal({
        open: true,
        dim: false,
        title: "부가서비스를 선택하지 않으셨습니다.",
        message: "선택을 완료하시겠습니까?",
        notxt: "취소",
        oktxt: "확인",
        callback1: () => {},
        callback2: () => {
          requestBuy();
        },
      });
    } else {
      console.log(consignmentStatus);
      if (consignmentStatus === false) {
        console.log("FALSE");
        setModal({
          open: true,
          dim: false,
          title: "배송지가 입력되지 않았습니다.",
          message: "선택을 완료하시겠습니까?",
          notxt: "취소",
          oktxt: "확인",
          callback1: () => {
            return;
          },
          callback2: () => {
            requestBuy();
          },
        });
      } else {
        if (addressIndex === 1) {
          if (address === "" || addressDetail === "") {
            handleToast("배송지 정보를 입력해주세요.");
            return;
          }
        }
        requestBuy();
      }
      // setModal({
      //   open: true,
      //   dim: false,
      //   title: "선택하신 제품이 없습니다.",
      //   message: "",
      //   notxt: "취소",
      //   oktxt: "확인",
      //   callback2: () => {
      //     null;
      //   },
      // });
    }
  };

  const requestBuy = async () => {
    const result = await fetchAddService();

    if (result !== undefined) {
      navigate("/mypage", {
        state: {
          tabIndex: 2,
        },
      });
    }
  };

  // 페이지 접속시 차량의 부가서비스 데이터를 가져와 chkItems 세팅
  const [locationIdx, setLocationIdx] = useState(0);

  const setAddressService = (services) => {
    const newArr = [];
    if (services.length !== 0) {
      services?.map((value) => {
        newArr.push(value.name);

        // 탁송 선택된 경우
        if (value.serviceType === "DELIVERY") {
          setConsignmentStatus(true); // 탭 노출
          const result = deliveryLocation.filter((location, idx) => {
            if (
              value.deliveryAddress + " " + value.deliveryAddressDetail ===
              location.address + " " + location.addressDetail
            ) {
              setLocationIdx(idx);
              setSelectedAddress({
                address: value.deliveryAddress,
                addressDetail: value.deliveryAddressDetail,
                sido: value.sido,
                sigungu: value.sigungu,
                sigunguCode: value.sigunguCode,
              });
              return location;
            }
          });

          // deliveryLocations 배열에 일치하는 값이 없다면 신규 입력 탭으로 전환
          if (result.length === 0) {
            setAddressIndex(1);
            setInputs({
              address: value.deliveryAddress,
              addressDetail: value.deliveryAddressDetail,
            });
            setSelectedAddress({
              address: value.deliveryAddress,
              addressDetail: value.deliveryAddressDetail,
              sido: value.sido,
              sigungu: value.sigungu,
              sigunguCode: value.sigunguCode,
            });
          }
        }
      });

      // setChkItems(newArr);
    }
  };

  /****************************************
   * TODO 공용 함수
   ****************************************/

  /****************************************
   * TODO 토스트 & 모달 상태
   ****************************************/
  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });
  //* 주소 검색 팝업
  const [addressPopup, setAddressPopup] = useState(false);
  const [daumAddress, setDaumAddress] = useState("");
  const [addressPost, setAddressPost] = useState("");
  const [daumData, setDaumData] = useState();

  // 팝업창 열기
  const openPostCode = () => {
    setAddressPopup(true);
  };
  // 팝업창 닫기
  const closePostCode = () => {
    setAddressPopup(false);
  };

  useEffect(() => {
    if (daumData != undefined) {
      setSelectedAddress({
        address: daumData.address,
        addressDetail: addressDetail,
        sido: daumData.sido,
        sigungu: daumData.sigungu,
        sigunguCode: daumData.sigunguCode,
      });
    }
  }, [daumData]);

  useEffect(() => {
    setInputs({
      ...inputs,
      ["address"]: daumAddress,
    });
  }, [daumAddress, addressPost]);

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.service}` + " global-width"}>
          {/* 화면 좌측 - 부가서비스 신청 */}
          <div className={styles.application}>
            <div className={styles.header}>
              <h1>
                {carDatas && carDatas?.length === 1
                  ? "부가 서비스 신청"
                  : `총 ${carDatas?.length}대 부가 서비스 신청`}
              </h1>
              <p className={styles.subTxt}>
                *해당 차량에 적용 가능한 부가 서비스만 나타납니다.
              </p>
            </div>
            <div className={styles.contents}>
              <div className={styles.itemWrapper}>
                {/* 금융서비스 */}
                {financialStatus && (
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>금융서비스</span>
                    </div>
                    <div className={styles.chkList}>
                      {items.map((item) => {
                        return (
                          item.serviceType === "FINANCIAL" && (
                            <div
                              className={`${styles.chkItem}` + " checkbox"}
                              key={item.id}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  value={item.name}
                                  name={item.serviceType}
                                  onChange={chkHandler}
                                  checked={
                                    chkItems1 === item.name ? true : false
                                  }
                                />
                                <div></div>
                                <span>
                                  {item.name}{" "}
                                  {/* <em>+ {Common.commaFormat(item.price)}원</em> */}
                                </span>
                              </label>
                            </div>
                          )
                        );
                      })}
                    </div>
                    <div className={styles.itemNote}>
                      <div className={styles.noteTit}>금융서비스란?</div>
                      <div className={styles.noteDesc}>
                        <p>모빌리카에서 제공하는 자동차금융 상품 입니다.</p>
                        <p>
                          자동차 구매에 대한 금융상품이 필요시 모빌리카 협력
                          금융사와 연결해드리고 있으며, 심사 후 렌터카사 보유
                          대출 한도 외 별도의 추가 대출 한도로 진행됩니다.
                        </p>
                        <p>
                          단, 별도 추가 대출 한도는 심사 후 정해지며, 부결 또는
                          부가 조건이 함께 진행될 수 있습니다.
                        </p>
                      </div>
                    </div>
                  </div>
                )}
                {/* 썬팅 */}
                {suntingStatus && (
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>썬팅</span>
                    </div>
                    <div className={styles.chkList}>
                      {items.map((item) => {
                        return (
                          item.serviceType === "SUNTING" && (
                            <div
                              className={`${styles.chkItem}` + " checkbox"}
                              key={item.id}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  value={item.name}
                                  name={item.serviceType}
                                  onChange={chkHandler}
                                  checked={
                                    chkItems2.indexOf(item.name) >= 0
                                      ? true
                                      : false
                                  }
                                  // checked={chkItems2 === item.name ? true : false}
                                />
                                <div></div>
                                <span>
                                  {item.name}{" "}
                                  <em>+ {Common.commaFormat(item.price)}원</em>
                                </span>
                              </label>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                )}
                {/* 블랙박스 */}
                {blackboxStatus && (
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>블랙박스</span>
                    </div>
                    <div className={styles.chkList}>
                      {items.map((item) => {
                        return (
                          item.serviceType === "BLACKBOX" && (
                            <div
                              className={`${styles.chkItem}` + " checkbox"}
                              key={item.id}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  value={item.name}
                                  name={item.serviceType}
                                  onChange={chkHandler}
                                  checked={
                                    chkItems3 === item.name ? true : false
                                  }
                                />
                                <div></div>
                                <span>
                                  {item.name}{" "}
                                  <em>+ {Common.commaFormat(item.price)}원</em>
                                </span>
                              </label>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                )}
                {/* 경정비 */}
                {organizationStatus && (
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>경정비</span>
                    </div>
                    <div className={styles.chkList}>
                      {items.map((item) => {
                        return (
                          item.serviceType === "ORGANIZATION" && (
                            <div
                              className={`${styles.chkItem}` + " checkbox"}
                              key={item.id}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  value={item.name}
                                  name={item.serviceType}
                                  onChange={chkHandler}
                                  checked={
                                    chkItems4.indexOf(item.name) >= 0
                                      ? true
                                      : false
                                  }
                                />
                                <div></div>
                                <span>
                                  {item.name}{" "}
                                  <em>+ {Common.commaFormat(item.price)}원</em>
                                </span>
                              </label>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                )}
                {/* 파손수리 (00판 수리 필요) */}
                {/* {repiarStatus && (
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>
                        파손수리 (00판 수리 필요)
                      </span>
                    </div>
                    <div className={styles.chkList}>
                      {items.map((item) => {
                        return (
                          item.serviceType === "REPIAR" && (
                            <div
                              className={`${styles.chkItem}` + " checkbox"}
                              key={item.id}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  value={item.name}
                                  name={item.serviceType}
                                  onChange={chkHandler}
                                  checked={
                                    chkItems5 === item.name ? true : false
                                  }
                                />
                                <div></div>
                                <span>
                                  {item.name}{" "}
                                  <em>+ {Common.commaFormat(item.price)}원</em>
                                </span>
                              </label>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                )} */}
                {/* 세차 */}
                {cleaningStatus && (
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>세차</span>
                    </div>
                    <div className={styles.chkList}>
                      {items.map((item) => {
                        return (
                          item.serviceType === "CLEANING" && (
                            <div
                              className={`${styles.chkItem}` + " checkbox"}
                              key={item.id}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  value={item.name}
                                  name={item.serviceType}
                                  onChange={chkHandler}
                                  checked={
                                    chkItems6.indexOf(item.name) >= 0
                                      ? true
                                      : false
                                  }
                                />
                                <div></div>
                                <span>
                                  {item.name}{" "}
                                  <em>+ {Common.commaFormat(item.price)}원</em>
                                </span>
                              </label>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                )}
                {/* 디테일링 */}
                {detailingStatus && (
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>디테일링</span>
                    </div>
                    <div className={styles.chkList}>
                      {items.map((item) => {
                        return (
                          item.serviceType === "DETAILING" && (
                            <div
                              className={`${styles.chkItem}` + " checkbox"}
                              key={item.id}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  value={item.name}
                                  name={item.serviceType}
                                  onChange={chkHandler}
                                  checked={
                                    chkItems7.indexOf(item.name) >= 0
                                      ? true
                                      : false
                                  }
                                />
                                <div></div>
                                <span>
                                  {item.name}{" "}
                                  <em>+ {Common.commaFormat(item.price)}원</em>
                                </span>
                              </label>
                            </div>
                          )
                        );
                      })}
                    </div>
                  </div>
                )}
                {/* 탁송 */}
                {deliveryStatus && (
                  <div className={styles.itemCont}>
                    <div className={styles.itemHeader}>
                      <span className={styles.itemTit}>탁송</span>
                    </div>
                    <div className={styles.chkList}>
                      {items.map((item) => {
                        return (
                          item.serviceType === "DELIVERY" && (
                            <div
                              className={`${styles.chkItem}` + " checkbox"}
                              key={item.id}
                            >
                              <label>
                                <input
                                  type="checkbox"
                                  value={item.name}
                                  name={item.serviceType}
                                  onChange={chkHandler}
                                  checked={
                                    chkItems8 === item.name ? true : false
                                  }
                                />
                                <div></div>
                                <span>
                                  {item.name}{" "}
                                  {/* <em>+ {Common.commaFormat(item.price)}원</em> */}
                                </span>
                              </label>
                            </div>
                          )
                        );
                      })}
                    </div>
                    <p className={styles.subTxt}>
                      이 차량은{" "}
                      <em>
                        {carDatas[0].car.seller.corpAddress +
                          " " +
                          carDatas[0].car.seller.corpAddressDetail}{" "}
                      </em>
                      에 있습니다. <br />* 탁송비는 도착지에 따라 요금이 별도
                      청구 됩니다.
                    </p>
                    {/* 탁송 - 배송지 */}
                    {consignmentStatus && (
                      <div className={styles.addressWrapper}>
                        <div className={styles.addHeader}>
                          <button
                            className={
                              addressIndex == 0
                                ? `${styles.btnRadio} ${styles.active}`
                                : `${styles.btnRadio}`
                            }
                            onClick={() => {
                              setAddressIndex(0);
                              setSelectedAddress({
                                address: deliveryLocation[0].address,
                                addressDetail:
                                  deliveryLocation[0].addressDetail,
                                sido: deliveryLocation[0].sido,
                                sigungu: deliveryLocation[0].sigungu,
                                sigunguCode: deliveryLocation[0].sigunguCode,
                              });
                            }}
                          >
                            등록 배송지
                          </button>
                          <button
                            className={
                              addressIndex == 1
                                ? `${styles.btnRadio} ${styles.active}`
                                : `${styles.btnRadio}`
                            }
                            onClick={() => {
                              setAddressIndex(1);
                              resetInputData();
                              setSelectedAddress({
                                address: "",
                                addressDetail: "",
                                sido: "",
                                sigungu: "",
                                sigunguCode: "",
                              });
                            }}
                          >
                            신규 배송지
                          </button>
                        </div>
                        <div className={styles.addCont}>
                          {addressIndex == 0 ? (
                            <div className={styles.originAdd}>
                              {deliveryLocation.map((location, idx) => {
                                return (
                                  <div
                                    className={
                                      `${styles.radioItem}` + " radiobox"
                                    }
                                    key={location.id}
                                  >
                                    <input
                                      type="radio"
                                      id={location.id}
                                      name="originAdd"
                                      // 신규라면 !idx (0번째)
                                      // defaultChecked={!idx}
                                      // 수정이라면 &&& 기존 배송지 목록에 포함된다면 (해당 인덱스값)
                                      defaultChecked={idx === locationIdx}
                                      onChange={() => {
                                        setSelectedAddress({
                                          address: location.address,
                                          addressDetail: location.addressDetail,
                                          sido: location.sido,
                                          sigungu: location.sigungu,
                                          sigunguCode: location.sigunguCode,
                                        });
                                      }}
                                    />
                                    <label htmlFor={location.id}>
                                      {location.address +
                                        " " +
                                        location.addressDetail}
                                    </label>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <div className={styles.newAdd}>
                              <div className="input-form">
                                <div className="form-field">
                                  <div className="input-element">
                                    <input
                                      type="text"
                                      name="address"
                                      className=""
                                      placeholder="주소"
                                      onChange={updateInputData}
                                      value={address}
                                      readOnly
                                    />
                                  </div>
                                  <button
                                    type="button"
                                    className="btn-address"
                                    onClick={openPostCode}
                                  >
                                    주소 검색
                                  </button>
                                </div>
                              </div>
                              <div className="input-form">
                                <div className="form-field">
                                  <div className="input-element">
                                    <input
                                      type="text"
                                      name="addressDetail"
                                      className=""
                                      placeholder="상세 주소 입력"
                                      onChange={updateInputData}
                                      value={addressDetail}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div
                                className={`${styles.chkItem}` + " checkbox"}
                              >
                                <label>
                                  <input
                                    type="checkbox"
                                    value="newAdd"
                                    onChange={() => {
                                      setNewAddChk(!newAddChk);
                                    }}
                                    checked={newAddChk}
                                  />
                                  <div></div>
                                  <span>배송지 목록에 추가</span>
                                </label>
                              </div>
                              {newAddChk && (
                                <div className="input-form">
                                  <div className="form-field">
                                    <div className="input-element">
                                      <input
                                        type="text"
                                        name="addressName"
                                        className=""
                                        placeholder="배송지 이름"
                                        onChange={updateInputData}
                                        value={addressName || ""}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
          {/* 화면 우측 - 부가서비스 선택 정보 */}
          <div className={styles.summary}>
            <div className={styles.header}>
              <h3>부가서비스 선택 정보 (VAT 포함)</h3>
            </div>
            <div className={styles.contents}>
              <div className={styles.itemList}>
                {selectedItems.length === 0 ? (
                  <div className={styles.item}>
                    <span className={styles.itemTit}>
                      선택된 서비스가 없습니다.
                    </span>
                  </div>
                ) : (
                  selectedItems.map((item) => {
                    return (
                      <div className={styles.item} key={item.id}>
                        <span className={styles.itemTit}>{item.name}</span>
                        <span className={styles.itemDesc}>
                          {Common.commaFormat(item.price)}원
                        </span>
                      </div>
                    );
                  })
                )}
              </div>
              {selectedItems.length === 0 ? null : (
                <div className={styles.total}>
                  <div className={styles.item}>
                    <span className={styles.itemTit}>합계</span>
                    <span className={styles.itemDesc}>
                      {Common.commaFormat(
                        selectedItems.reduce((sum, currValue) => {
                          return sum + currValue.price;
                        }, 0)
                      ) + "원"}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className={styles.buttons}>
              <div className="button-form">
                <button
                  type="button"
                  className="btn btn-xl btn--primary"
                  onClick={onComplete}
                >
                  선택 완료
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}

      {/* 주소 팝업 */}
      {addressPopup && (
        <DaumPost
          onClose={closePostCode}
          setAddress={setDaumAddress}
          setAddressPost={setAddressPost}
          setDaumData={setDaumData}
        />
      )}
    </>
  );
};

export default Service;
