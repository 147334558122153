/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

import Toast from "../components/Toast.js";
import ModalConfirm from "../components/ModalConfirm.js";
import styles from "./Contact.module.scss";

import emptyIcon from "../assets/icons/empty.svg";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const NOTICE_URL = "/api/user/notice/v1";
const FAQ_URL = "/api/user/faq/v1";

const Contact = () => {
  const navigate = useNavigate();

  // 탭 인덱스
  const [tabIndex, setTabIndex] = useState(0);

  // Q&A 아이템 노출 상태
  const handleDetails = () => {
    const details = document.querySelectorAll("details");
    details.forEach((targetDetail) => {
      targetDetail.addEventListener("click", () => {
        details.forEach((detail) => {
          if (detail !== targetDetail) {
            detail.removeAttribute("open");
          }
        });
      });
    });
  };

  //* API loading state
  const [loading, setLoading] = useState(true);
  const [noticeItems, setNoticeItems] = useState([]); // 공지사항
  const [faqItems, setFaqItems] = useState([]); // FAQ

  /****************************************
   * TODO Pagination Init
   ****************************************/
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(0); // 현재 페이지 번호
  const rangeSize = 10;

  useEffect(() => {
    window.scrollTo(0, 0);

    if (tabIndex === 0) {
      fetchNoticeData();
    } else if (tabIndex === 1) {
      fetchFaqData();
    }
  }, [tabIndex, page]);

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 토큰 상태 확인
  const fetchMyData = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });
      return response;
    } catch (err) {
      console.error(err.response?.data.message);
      return false;
    }
  };

  // 공지사항
  const fetchNoticeData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        BASE_URL + NOTICE_URL + `?page=${page}&size=${rangeSize}`
      );

      setPagination(response.data.pagination);
      setNoticeItems(response.data.data); // 공지사항
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };
  // FAQ
  const fetchFaqData = async () => {
    setLoading(true);
    try {
      const response = await axios.get(BASE_URL + FAQ_URL);

      setFaqItems(response.data.data); // 공지사항
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.contact}` + " global-width"}>
          <div className={styles.contents}>
            <div className={styles.tabWrapper}>
              <div className={styles.tabHeader}>
                <button
                  className={
                    tabIndex == 0
                      ? `${styles.btnRadio} ${styles.active}`
                      : `${styles.btnRadio}`
                  }
                  onClick={() => {
                    setTabIndex(0);
                  }}
                >
                  공지사항
                </button>
                <button
                  className={
                    tabIndex == 1
                      ? `${styles.btnRadio} ${styles.active}`
                      : `${styles.btnRadio}`
                  }
                  onClick={() => {
                    setTabIndex(1);
                  }}
                >
                  자주 묻는 질문
                </button>
              </div>
              <div className={styles.tabContainer}>
                {/* 탭 1 : 관심 차량 설정 */}
                {tabIndex == 0 ? (
                  <div className={`${styles.tab} ${styles.tabNotice}`}>
                    <div className={styles.tabHeader}>
                      <span className={styles.tabTit}>공지사항</span>
                    </div>
                    <div className={styles.tabInner}>
                      <div className={styles.itemWrapper}>
                        <div className={styles.contHeader}>
                          <div className={styles.item}>
                            <div className={styles.tdContent}>
                              <span className={styles.tdTit}>제목</span>
                            </div>
                            <div className={styles.tdDate}>
                              <span className={styles.tdTit}>등록</span>
                            </div>
                          </div>
                        </div>
                        {noticeItems.length === 0 ? (
                          <div className={styles.nonItems}>
                            <div className={styles.icon}>
                              <img src={emptyIcon} alt="" />
                            </div>
                            <p>게시글이 없습니다.</p>
                          </div>
                        ) : (
                          <div className={styles.itemCont}>
                            <div className={styles.itemList}>
                              {noticeItems.map((item, idx) => {
                                return (
                                  <div className={styles.item} key={item.id}>
                                    <div className={styles.tdContent}>
                                      <Link
                                        to={`/postDetail/${item.id}`}
                                        className={styles.mainTxt}
                                      >
                                        {item.title}
                                      </Link>
                                    </div>
                                    <div className={styles.tdDate}>
                                      <div className={styles.descTxt}>
                                        {item.createdAt
                                          .split("T")[0]
                                          .replaceAll("-", ".")}
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>

                            {/* pagination */}
                            <div className={styles.pagination}>
                              <div className={styles.numList}>
                                <div
                                  className={styles.btnPrev}
                                  onClick={() => setPage(pagination.prevPage)}
                                  aria-current={
                                    pagination.isFirst ? false : true
                                  }
                                >
                                  <AiOutlineLeft />
                                </div>
                                <div
                                  className={styles.btnNext}
                                  onClick={() => setPage(pagination.nextPage)}
                                  aria-current={
                                    pagination.isLast ? false : true
                                  }
                                >
                                  <AiOutlineRight />
                                </div>
                                <ul className={styles.currentPage}>
                                  {Array(pagination.endPage)
                                    .fill()
                                    .map(
                                      (_, i) =>
                                        i >= pagination.startPage - 1 && (
                                          <li
                                            key={i}
                                            onClick={() => setPage(i)}
                                            className={
                                              pagination.page === i
                                                ? `${styles.active}`
                                                : null
                                            }
                                          >
                                            {i + 1}
                                          </li>
                                        )
                                    )}
                                </ul>
                                {pagination.endPage !==
                                  pagination.totalPages && (
                                  <div className={styles.lastPage}>
                                    <li
                                      onClick={() =>
                                        setPage(pagination.totalPages - 1)
                                      }
                                    >
                                      {pagination.totalPages}
                                    </li>
                                  </div>
                                )}
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ) : null}
                {/* 탭 2 : 자주 묻는 질문 */}
                {tabIndex == 1 ? (
                  <div className={`${styles.tab} ${styles.tabQna}`}>
                    <div className={styles.tabHeader}>
                      <span className={styles.tabTit}>자주 묻는 질문</span>
                    </div>
                    <div className={styles.tabInner}>
                      <div className={styles.itemWrapper}>
                        <div className={styles.accordian}>
                          {/* {faqItems.length !== 0 &&
                            faqItems.map((item, idx) => {
                              return (
                                <details key={idx}>
                                  <summary onClick={handleDetails}>
                                    {item.question}
                                  </summary>
                                  <div className={styles.anwser}>
                                    {item.answer}
                                  </div>
                                </details>
                              );
                            })} */}

                          <details>
                            <summary onClick={handleDetails}>
                              차량 구매 시 수수료기준은 어떻게 되나요?
                            </summary>
                            <div className={styles.anwser}>
                              수수료 기준은 아래와 같습니다. <br />
                              <br />
                              1. 경형 / 준중형 세단 / 소형 SUV : 20만원 <br />
                              2. 중형세단, 준중형 SUV 이상 / 기타 수입차량 :
                              30만원 <br />
                              ※전체 수수료는 '부가세 포함'금액으로 책정되어
                              있습니다.
                            </div>
                          </details>
                          <details>
                            <summary onClick={handleDetails}>
                              차량 구입 이외의 서비스도 진행이 가능하나요?
                            </summary>
                            <div className={styles.anwser}>
                              부가 서비스는 아래와 같습니다. <br />
                              <br />
                              [모빌리카]를 통해 차량을 구매하시게 되면, 블랙박스 /
                              틴팅(썬팅) / 단순 외판 수리 / 타이어 교체 등
                              일반적인 차량 상품화에 필요한 상품은 연계
                              구매가 가능하며, 차량 구입과 동시에 '부가서비스'
                              신청을 통해, 상품화가 완료된 차량으로 받아보실 수
                              있습니다. <br />
                              <br />※ 일부 부가서비스의 경우 회원사가 지정한
                              장소에서 실행될 수도 있습니다.
                            </div>
                          </details>
                          <details>
                            <summary onClick={handleDetails}>
                              차량을 직접 볼 수는 없나요?
                            </summary>
                            <div className={styles.anwser}>
                              차량 판매를 위해 차고지에 대기 중 일 경우 차량
                              확인이 가능합니다. 그러나, 일부 차량의 경우 차량이
                              운행 중인 차량인 경우도 있어, 실제 차량 확인이
                              어려울 수도 있으니, 참고 부탁드립니다.
                            </div>
                          </details>
                          <details>
                            <summary onClick={handleDetails}>
                              거래절차가 궁금합니다.
                            </summary>
                            <div className={styles.anwser}>
                              거래절차는 아래와 같습니다. <br />
                              <br />
                              차량 구매 요청 → 차량 구매 승인 → [모빌리카]
                              구매요청서(구입 신청서) 작성 → [모빌리카] 수수료 입금
                              → 양수, 양도 업체 간 차량 금액 대금 및 이전 서류
                              작업 진행 → 차량 이전 확인 및 출고
                            </div>
                          </details>
                          <details>
                            <summary onClick={handleDetails}>
                              계약요청 후 취소가 가능한가요?
                            </summary>
                            <div className={styles.anwser}>
                              계약요청에 따른 승인 또는 수수료 입금 요청메시지
                              수신 후 7일 내 진행하시지 않을 경우 자동 계약
                              취소가 되오니 참고 부탁드립니다. 또한, 모든 계약
                              단계 이후 취소를 희망하실 경우, [모빌리카] 고객센터
                              개별 문의를 통한 취소를 요청하셔야 합니다. <br />
                              <br />☎ 모빌리카 대표번호 : 1644-4145
                            </div>
                          </details>
                          <details>
                            <summary onClick={handleDetails}>
                              결제수단은 무엇이 있나요?
                            </summary>
                            <div className={styles.anwser}>
                              1. 현금결제, 마일리지 결제 가능합니다. <br />
                              <br />
                              2. 금융 결제의 경우 [모빌리카] 협력 금융사를 통해
                              합리적인 금융 상품을 통해 진행하실 수 있습니다.
                            </div>
                          </details>
                          <details>
                            <summary onClick={handleDetails}>
                              회원과 비회원의 차이가 있나요?
                            </summary>
                            <div className={styles.anwser}>
                              회원으로 가입하시면 아래와 같은 혜택을
                              제공해드립니다.
                              <br />
                              <br />
                              10만 원 상당의 쿠폰 및 포인트 지급
                              <br />
                              이벤트 참여를 통한 포인트 획득
                              <br />
                              관심 차량에 대한 소식 받기
                              <br />
                              <br />
                              위와같은 다양한 혜택이 고객님들을 위해 준비되어
                              있습니다.
                              <p className={styles.link}>
                                <a
                                  onClick={async () => {
                                    //* 토큰 상태 확인
                                    const result = await fetchMyData();

                                    if (!result) {
                                      navigate("/join");
                                    } else {
                                      handleToast("로그아웃 후 이용해주세요.");
                                    }
                                  }}
                                >
                                  [회원가입 바로가기]
                                </a>
                              </p>
                            </div>
                          </details>
                          <details>
                            <summary onClick={handleDetails}>
                              모빌리카 마일리지 정책이 궁금합니다.
                            </summary>
                            <div className={styles.anwser}>
                              1. 안내
                              <br />
                              [모빌리카] 마일리지는 차량 양수도를 더욱 쾌적하게
                              진행될 수 있도록 만들어진 제도입니다. 마일리지
                              충전을 통해 빠르게 결제를 진행하고 추가 마일리지를
                              적립 받아 보다 저렴한 금액으로 차량을 구입하시길
                              바랍니다. <br />
                              <br />
                              2. 마일리지 적립비율(100만원 부터 적립)
                              <br />
                              일반회원 : 쿠폰 지급
                              <br />
                              실버회원 : 2% 추가 지급
                              <br />
                              골드회원 : 3% 추가 지급
                              <br />
                              플래티넘 회원 : 4% 추가 지급
                              <br />
                              VVIP 회원 : 5% 추가 지급
                            </div>
                          </details>
                          <details>
                            <summary onClick={handleDetails}>
                              모빌리카 포인트 정책이 궁금합니다.
                            </summary>
                            <div className={styles.anwser}>
                              1. 안내 <br />
                              홈페이지에서 포인트는 현금과 동일한 효력으로
                              작용됩니다.
                              <br />
                              포인트 유효기간은 적립 일로부터 12개월까지
                              유효합니다.
                              <br />
                              <br />
                              2. 적립기준
                              <br />
                              [모빌리카] 구매 : 회원등급별 차등 적립
                              <br />
                              일반회원 : 1%
                              <br />
                              실버회원 : 2%
                              <br />
                              골드회원 : 3%
                              <br />
                              플래티넘 회원 : 4%
                              <br />
                              VVIP 회원 : 5%
                              <br />
                              <br />
                              3. 사용 기준
                              <br />
                              [모빌리카] 수수료 / 부가서비스 구매 : 보유 포인트 중
                              10,000point 이상 단위부터 사용 가능
                            </div>
                          </details>
                          <details>
                            <summary onClick={handleDetails}>
                              고객정보 수정은 어떻게 하나요?
                            </summary>
                            <div className={styles.anwser}>
                              1. ‘마이페이지 → 회원정보’페이지를 통하여 배송지
                              정보, 담당자 정보 등을 변경하실 수 있습니다.
                              <br />
                              <br />
                              2. ‘법인정보’수정은 [모빌리카]고객센터를 통한
                              개별문의를 통하여 변경요청 접수 부탁드리겠습니다.
                              <br />
                              <p className={styles.link}>
                                <a
                                  onClick={async () => {
                                    //* 토큰 상태 확인
                                    const result = await fetchMyData();

                                    if (!result) {
                                      setModal({
                                        open: true,
                                        dim: true,
                                        title: "로그인이 필요합니다.",
                                        message: "지금 로그인 하시겠습니까?",
                                        notxt: "취소",
                                        oktxt: "로그인 하기",
                                        callback1: () => {},
                                        callback2: () => {
                                          navigate("/login");
                                        },
                                      });
                                    } else {
                                      navigate("/mypage");
                                    }
                                  }}
                                >
                                  [마이페이지 바로가기]
                                </a>
                              </p>
                            </div>
                          </details>
                          <details>
                            <summary onClick={handleDetails}>
                              회원 탈퇴는 어떻게 하나요?
                            </summary>
                            <div className={styles.anwser}>
                              1. 회원 탈퇴는 고객센터로 접수 부탁드립니다.
                              <br />
                              ☎ 모빌리카 대표번호 : 1644-4145 <br />
                              <br />
                              2. 회원 탈퇴하시면 3개월 이내에 재가입은
                              불가능합니다.
                              <br />
                              반복적인 가입/탈퇴를 통한 부정적인 거래를 방지하기
                              위하여 재가입을 막고 있으니, 서비스 이용에 양해
                              부탁드리겠습니다.
                              <br />
                              <br />
                              3. 회원 탈퇴 후 즉시 재가입이 필요한 경우
                              고객센터로 문의하시기 바랍니다.
                            </div>
                          </details>
                          <details>
                            <summary onClick={handleDetails}>
                              이미 가입된 회원 이라고 나옵니다.
                            </summary>
                            <div className={styles.anwser}>
                              1. [모빌리카]에 과거 가입이력이 있으신 경우 [아이디
                              찾기]를 통해 확인 후 로그인해 주세요.
                              <br />
                              <p className={styles.link}>
                                [<Link to="/findId">아이디</Link> /{" "}
                                <Link to="/findPw">비밀번호</Link> 찾기
                                바로가기]
                              </p>
                              <br />
                              2. [모빌리카]에 최초 회원가입 중, 이미 가입된 회원으로
                              가입이 불가능하실 경우 고객센터에 문의해 주시기
                              바랍니다.
                              <br />☎ 모빌리카 고객센터 : 1644-4145
                            </div>
                          </details>
                          <details>
                            <summary onClick={handleDetails}>
                              아이디/비밀번호를 변경할 수 있나요?
                            </summary>
                            <div className={styles.anwser}>
                              1. ID(계정)
                              <br />
                              이미 등록된 아이디(계정)는 변경이 불가능합니다.
                              현재 사용하고 있는 아이디(계정)를 탈퇴 후, 재가입
                              부탁드리겠습니다.
                              <br />
                              <br />
                              2. 비밀번호
                              <br />
                              ‘마이페이지 &gt; 회원정보 &gt; 비밀번호
                              변경하기’를 통해 비밀번호 변경이 가능합니다.
                              <br />
                              <br />
                              <p className={styles.link}>
                                <a
                                  onClick={async () => {
                                    //* 토큰 상태 확인
                                    const result = await fetchMyData();

                                    if (!result) {
                                      setModal({
                                        open: true,
                                        dim: true,
                                        title: "로그인이 필요합니다.",
                                        message: "지금 로그인 하시겠습니까?",
                                        notxt: "취소",
                                        oktxt: "로그인 하기",
                                        callback1: () => {},
                                        callback2: () => {
                                          navigate("/login");
                                        },
                                      });
                                    } else {
                                      navigate("/resetPw");
                                    }
                                  }}
                                >
                                  [비밀번호 변경 바로가기]
                                </a>
                              </p>
                            </div>
                          </details>
                          <details>
                            <summary onClick={handleDetails}>
                              로그인 시 아이디 또는 비밀번호 오류라고 나옵니다.
                            </summary>
                            <div className={styles.anwser}>
                              1. 로그인이 정상적으로 되지 않으신다면 아래를
                              확인해 주세요. <br />
                              <br />
                              ID와 비밀번호는 대/소문자를 구분합니다. 입력 시
                              다시 한번 확인해 주세요. 동일하게 로그인이 안
                              되신다면, 모빌리카 고객센터로 문의 부탁드리겠습니다.
                              <br />
                              ☎ [모빌리카] 고객센터 : 1644-4145
                              <br />
                              <br />
                              2. 가입 당시의 ID와 비밀번호를 다시 확인해 주세요.
                              ID와 비밀번호 분실 시 [아이디 / 비밀번호 찾기]를
                              통하여 확인해 주세요.
                              <br />
                              <p className={styles.link}>
                                [<Link to="/findId">아이디</Link> /{" "}
                                <Link to="/findPw">비밀번호</Link> 찾기
                                바로가기]
                              </p>
                            </div>
                          </details>
                        </div>

                        {/* pagination */}
                        {/* <div className={styles.pagination}>
                        <div className={styles.numList}>
                          <div className={styles.btnPrev}>
                            <AiOutlineLeft />
                          </div>
                          <div className={styles.btnNext}>
                            <AiOutlineRight />
                          </div>
                          <ul className={styles.currentPage}>
                            <li className={styles.active}>
                              <a href="">1</a>
                            </li>
                            <li>
                              <a href="">2</a>
                            </li>
                            <li>
                              <a href="">3</a>
                            </li>
                            <li>
                              <a href="">4</a>
                            </li>
                            <li>
                              <a href="">5</a>
                            </li>
                          </ul>
                          <div className={styles.lastPage}>
                            <li>
                              <a href="">11</a>
                            </li>
                          </div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default Contact;
