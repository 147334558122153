/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import DatePicker from "react-datepicker";
import { ko } from "date-fns/esm/locale";

import * as Common from "../../actions/Common.js";
import ModalConfirm from "../../components/ModalConfirm.js";
import Toast from "../../components/Toast.js";
import ModalMileage from "../../components/Modal_mileage.js";
import styles from "./Mileage.module.scss";
import emptyIcon from "../../assets/icons/empty.svg";
import { GrFormPrevious } from "react-icons/gr";
import { GrFormNext } from "react-icons/gr";
import { AiOutlineLeft } from "react-icons/ai";
import { AiOutlineRight } from "react-icons/ai";

// API URL
import { baseUrl } from "../../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const MILEAGE_URL = "/api/user/mileage/v1/me";

const Mileage = () => {
  const navigate = useNavigate();

  /****************************************
   * TODO Pagination Init
   ****************************************/
  const [pagination, setPagination] = useState([]);
  const [page, setPage] = useState(0); // 현재 페이지 번호
  const rangeSize = 10;

  // 데이트피커
  const [startDate, setStartDate] = useState(); // 시작 날짜
  const [endDate, setEndDate] = useState(new Date()); // 종료 날짜

  // 마일리지 충전 모달
  const [mileageModalStatus, setMileageModalStatus] = useState(false);
  /****************************************
   * TODO 초기 세팅
   ****************************************/
  const [loading, setLoading] = useState(true);
  const [myData, setMyData] = useState([]); // 유저 정보
  const [items, setItems] = useState([]);
  const [deliveryLocation, setDeliveryLocation] = useState([]);

  useEffect(() => {
    const today = new Date();
    const lastMonth = new Date(today.setMonth(today.getMonth() - 1));

    setStartDate(lastMonth);
  }, []);

  useEffect(() => {
    if (startDate === undefined || endDate === undefined) {
      return;
    }

    let monthGap = endDate.getMonth() - startDate.getMonth();

    if (startDate > endDate) {
      handleToast("시작 날짜가 종료 날짜보다 가깝습니다.");
      return;
    }
    if (monthGap > 6) {
      setEndDate(startDate);

      // const pastDay = new Date(startDate);
      // const newEndDay = new Date(pastDay.setMonth(pastDay.getMonth() + 6));
      // setEndDate(newEndDay);

      handleToast("이용 내역은 6개월 단위로 조회 가능합니다.");
      return;
    }

    window.scrollTo(0, 0);
    fetchData();
  }, [page, startDate, endDate]);

  const fetchData = async () => {
    getUserId();
    fetchMileage();
  };

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  const getUserId = async () => {
    setLoading(true);

    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setMyData(response.data.data);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };

  // 쿠폰 데이터 가져오기
  const fetchMileage = async () => {
    setLoading(true);

    try {
      const parameter = `?page=${page}&size=${rangeSize}&started_at=${Common.dateToString(startDate, "start")}&ended_at=${Common.dateToString(endDate, "end")}`;

      const response = await axios.get(BASE_URL + MILEAGE_URL + parameter, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setPagination(response.data.pagination);
      setItems(response.data.data);
      setLoading(false);
    } catch (err) {
      if (err.response?.status === 401) {
        setModal({
          open: true,
          dim: true,
          title: "세션이 만료되었습니다.",
          message: "로그인 화면으로 이동합니다.",
          notxt: "",
          oktxt: "확인",
          callback2: () => {
            navigate("/logout");
          },
        });
        return;
      } else {
        handleToast(err.response?.data.message);
        setLoading(false);
        return false;
      }
    }
  };

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = txt => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      {loading ? (
        <div className="loader"></div>
      ) : (
        <div className={`${styles.mileage}` + " global-width"}>
          <div className={styles.infoSummary}>
            <div className={styles.possession}>
              <div className={styles.tit}>
                <span>보유 마일리지</span>
              </div>
              <div className={styles.desc}>
                <span>{Common.commaFormat(myData.mileage)}원</span>
                <button type="button" onClick={() => setMileageModalStatus(true)}>
                  충전하기
                </button>
              </div>
            </div>
            <div className={styles.sumDesc}>
              <p>마일리지는 모빌리카에서 사용되는 가상화폐 입니다.</p>
              <p>충전 시 1:1 비율로 현금처럼 사용이 가능합니다.</p>
              <p>마일리지는 타인에게 양도하거나 양도 받을 수 없습니다.</p>
              <p>마일리지에 대한 금융이자는 지급되지 않습니다.</p>
              <p>등급에 의해 추가로 적립된 마일리지는 환불이 불가 합니다.</p>
            </div>
          </div>
          <div className={styles.header}>
            <h1>마일리지 이용 내역</h1>
            <div className={styles.dateRange}>
              <DatePicker
                locale={ko}
                dateFormat="yyyy-MM-dd"
                className={styles.datepicker}
                placeholderText="Weeks start on Monday"
                selected={startDate}
                // minDate={new Date()}
                maxDate={new Date()}
                onChange={date => setStartDate(date)}
                renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                  <div className="cd-header">
                    <p className="ch-tit">날짜 선택</p>
                    <button className="cd-arrow cd-prev" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      <GrFormPrevious className="icon" />
                    </button>

                    <div className="cd-nav">
                      {date.getFullYear()}. {date.getMonth() + 1}
                    </div>

                    <button className="cd-arrow cd-next" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      <GrFormNext className="icon" />
                    </button>
                  </div>
                )}
              />
              <DatePicker
                locale={ko}
                dateFormat="yyyy-MM-dd"
                className={styles.datepicker}
                placeholderText="Weeks start on Monday"
                selected={endDate}
                // minDate={new Date()}
                maxDate={new Date()}
                onChange={date => setEndDate(date)}
                renderCustomHeader={({ date, decreaseMonth, increaseMonth, prevMonthButtonDisabled, nextMonthButtonDisabled }) => (
                  <div className="cd-header">
                    <p className="ch-tit">날짜 선택</p>
                    <button className="cd-arrow cd-prev" onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                      <GrFormPrevious className="icon" />
                    </button>

                    <div className="cd-nav">
                      {date.getFullYear()}. {date.getMonth() + 1}
                    </div>

                    <button className="cd-arrow cd-next" onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                      <GrFormNext className="icon" />
                    </button>
                  </div>
                )}
              />
            </div>
          </div>
          <div className={styles.contents}>
            <div className={styles.itemWrapper}>
              <div className={styles.contHeader}>
                <div className={styles.item}>
                  <div className={styles.tdContent}>
                    <span className={styles.tdTit}>마일리지 내용</span>
                  </div>
                  <div className={styles.tdAmount}>
                    <span className={styles.tdTit}>마일리지</span>
                  </div>
                  <div className={styles.tdNumber}>
                    <span className={styles.tdTit}>구매번호</span>
                  </div>
                  <div className={styles.tdDate}>
                    <span className={styles.tdTit}>적용일시</span>
                  </div>
                </div>
              </div>
              {items.length == 0 ? (
                <div className={styles.nonItems}>
                  <div className={styles.icon}>
                    <img src={emptyIcon} alt="" />
                  </div>
                  <p>이용내역이 없습니다.</p>
                </div>
              ) : (
                <div className={styles.itemCont}>
                  <div className={styles.itemList}>
                    {items.map((item, idx) => {
                      return (
                        <div key={item.id} className={item.mileageType === "MINUS" || item.mileageType === "USED_FINISH" ? `${styles.item} ${styles.minus}` : `${styles.item} ${styles.plus}`}>
                          <div className={styles.tdContent}>
                            <div className={styles.mainTxt}>{item.description}</div>
                          </div>
                          <div className={styles.tdAmount}>
                            <div className={styles.descTxt}>{item.mileageType === "MINUS" || item.mileageType === "USED_FINISH" ? "-" + Common.commaFormat(item.usedMileage) : "+" + Common.commaFormat(item.usedMileage)}</div>
                          </div>
                          <div className={styles.tdNumber}>
                            <div className={styles.descTxt}>{item.dealId}</div>
                          </div>
                          <div className={styles.tdDate}>
                            <div className={styles.descTxt}>{item.handledAt?.split("T")[0].replaceAll("-", ".")}</div>
                          </div>
                        </div>
                      );
                    })}
                  </div>

                  {/* pagination */}
                  {pagination.totalPages > 1 && (
                    <div className={styles.pagination}>
                      <div className={styles.numList}>
                        <div className={styles.btnPrev} onClick={() => setPage(pagination.prevPage)} aria-current={pagination.isFirst ? false : true}>
                          <AiOutlineLeft />
                        </div>
                        <div className={styles.btnNext} onClick={() => setPage(pagination.nextPage)} aria-current={pagination.isLast ? false : true}>
                          <AiOutlineRight />
                        </div>
                        <ul className={styles.currentPage}>
                          {Array(pagination.endPage)
                            .fill()
                            .map(
                              (_, i) =>
                                i >= pagination.startPage - 1 && (
                                  <li key={i} onClick={() => setPage(i)} className={pagination.page === i ? `${styles.active}` : null}>
                                    {i + 1}
                                  </li>
                                )
                            )}
                        </ul>
                        {pagination.endPage !== pagination.totalPages && (
                          <div className={styles.lastPage}>
                            <li onClick={() => setPage(pagination.totalPages - 1)}>{pagination.totalPages}</li>
                          </div>
                        )}
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* (새창) 관심차량 설정 모달 */}
      {mileageModalStatus ? <ModalMileage deleter={setMileageModalStatus} fetchData={fetchData} mileage={myData.mileage} /> : null}

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && <ModalConfirm dim={modal.dim} setModal={setModal} title={modal.title} message={modal.message} notxt={modal.notxt} oktxt={modal.oktxt} callback1={modal.callback1} callback2={modal.callback2} />}
    </>
  );
};

export default Mileage;
