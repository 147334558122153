/* eslint-disable */
import React, { useMemo, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import ApiController from "../api/ApiController";
import Toast from "../components/Toast.js";
import Select, { components, MenuListProps } from "react-select";
import AsyncSelect from "react-select/async";
import ProgressBar from "../components/ProgressBar.js";
import Modal_info from "../components/Modal_info.js";
import DaumPost from "../components/DaumPost.js";
import styles from "./Join.module.scss";

import fileUploadIcon from "../assets/icons/PluscirclePr.svg";
import fileDeleteIcon from "../assets/icons/ClosecircleGr.svg";

import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const BANKLIST_URL = "/api/user/bank/v1";
const JOIN_URL = "/api/user/join/v1/join";
const TERMS_URL = "/api/user/term/v1";
const IDCHECK_URL = "/api/user/join/v1/exist_login_id";
const BUSINESS_CHECK_URL = "/api/user/join/v1/exist_business_number";
const MANAGER_CHECK_URL = "/api/user/join/v1/exist_manager_phone";
const UPLOAD_URL = "/api/user/upload/v1/upload/image";
const AUTH_SEND_URL = "/api/user/auth/v1/phone/send";
const AUTH_VALIDATION_URL = "/api/user/auth/v1/phone/validation";

const Join = () => {
  let navigate = useNavigate();

  /****************************************
   * TODO 탭 기능
   ****************************************/
  //* 회원가입 탭 화면
  const tabCount = 5;
  const [tabIndex, setTabIndex] = useState(0);
  const [allowStep, setAllowStep] = useState(1);
  const [btnState, setBtnState] = useState(false);
  const [progressValue, setProgressValue] = useState(0);
  const [isManagerCheck, setIsManagerCheck] = useState(false);
  const [isManagerAuth, setIsManagerAuth] = useState(false);
  const [findDataId, setFindDataId] = useState();
  const [authNum, setAuthNum] = useState();

  const [idWarnStatus, setIdWarnStatus] = useState(true);
  const [idWarnTxt, setIdWarnTxt] = useState("");
  const [pwWarnStatus, setPwWarnStatus] = useState(true);
  const [pwWarnTxt, setPwWarnTxt] = useState("");
  const [licenseWarnStatus, setLicenseWarnStatus] = useState(true);
  const [licenseWarnTxt, setLicenseWarnTxt] = useState("");
  const [corpWarnStatus, setCorpWarnStatus] = useState(true);
  const [corpWarnTxt, setCorpWarnTxt] = useState("");

  const handleProgressBar = async () => {
    if (btnState != false) {
      // 아이디/비밀번호 유효성 검사
      if (tabIndex === 1) {
        if (loginId.length < 6) {
          setIdWarnStatus(false);
          setIdWarnTxt("최소 6글자 이상 입력해주세요.");
          return;
        }
        if (password !== confirmPwd) {
          setPwWarnStatus(false);
          setPwWarnTxt("비밀번호가 일치하지 않습니다.");
          return;
        }
        if (password.length < 8) {
          setPwWarnStatus(false);
          setPwWarnTxt("최소 8글자 이상 입력해주세요.");
          return;
        }
        if ((await checkId()) === false) {
          // 아이디 중복 검사
          setIdWarnStatus(false);
          setIdWarnTxt("아이디가 이미 존재합니다.");
          return;
        }
        setPwWarnStatus(true);
        setIdWarnStatus(true);
      }

      // 법인 정보 유효성 검사
      if (tabIndex === 2) {
        if (licenseNumber.length !== 10) {
          setLicenseWarnStatus(false);
          setLicenseWarnTxt("사업자 등록번호를 확인해주세요.");
          return;
        }
        if (corpRegNumber.length !== 13) {
          setCorpWarnStatus(false);
          setCorpWarnTxt("법인 등록번호를 확인해주세요.");
          return;
        }

        if ((await fetchCheckBusiness()) === false) {
          // 사업자 등록번호 & 법인 등록번호 중복 검사
          return;
        }

        setLicenseWarnStatus(true);
        setCorpWarnStatus(true);
      }

      // 담당자 정보 유효성 검사
      // if (tabIndex === 3) {
      //   if ((await fetchCheckManager()) === false) {
      //     // 담당자 연락처 중복 검사
      //     return;
      //   }
      // }

      // 마지막 탭 : 사진 API 전송 후 res 결과에 따라 폼 데이터 전송
      if (tabIndex + 1 === tabCount) {
        const result1 = await fetchImageData();
        const result2 = await fetchFormData(result1.data.data.id);
        if (result2 === false) {
          return;
        } else {
          navigate("/welcome");
        }
      } else {
        setTabIndex(prev => prev + 1);
        switch (tabIndex) {
          case 0:
            setAllowStep(2);
            break;
          case 1:
            setAllowStep(3);
            break;
          case 2:
            setAllowStep(4);
            break;
          case 3:
            setAllowStep(5);
            break;
        }

        // setProgressValue((prev) => prev + 100 / tabCount);
        setBtnState(false);

        setTimeout(() => {
          window.scrollTo({ top: 0, behavior: "smooth" });
        }, 10);
      }
    }
  };

  useEffect(() => {
    // 탭이 바뀔 때 프로그레스바 진행
    const step = 100 / tabCount;

    setProgressValue((tabIndex + 1) * step);
  }, [tabIndex]);

  useEffect(() => {
    fetchTerms(); // 이용약관 가져오기
  }, []);

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  //* 입력 데이터 State
  const [inputs, setInputs] = useState({
    // 탭 2
    loginId: "", // 아이디
    password: "", // 비밀번호
    confirmPwd: "", // 비밀번호 확인
    // 탭 3
    corpName: "", // 법인명
    licenseNumber: "", // 사업자 등록번호
    corpRegNumber: "", // 법인 등록번호
    address: "", // 사업자 주소
    addressDetail: "", // 사업자 주소(상세)
    repName: "", // 대표자 성함
    repPhone: "", // 대표자 연락처
    corpPhone: "", // 대표 번호
    refundBankName: "", // 환불 계좌 정보(은행)
    refundBankOwner: "", // 환불 계좌 정보 (예금주)
    refundBankAccount: "", // 환불 계좌 정보(계좌번호)
    // 탭 4
    managerName: "", // 담당자 성함
    managerDivision: "", // 담당 부서
    managerPosition: "", // 직급
    managerPhone: "", // 담당자 연락처
    managerPhoneAuth: "", // 인증번호
    managerEmail: "", // 담당자 이메일
    // 탭 5
    taxEmail: "", // 세금계산서 이메일
    shipAddress: "", // 사업자 주소
    shipAddressDetail: "", // 사업자 주소(상세)
  });
  const { loginId, password, confirmPwd, corpName, licenseNumber, corpRegNumber, address, addressDetail, repName, repPhone, corpPhone, refundBankName, refundBankOwner, refundBankAccount, managerName, managerDivision, managerPosition, managerPhone, managerPhoneAuth, managerEmail, taxEmail, shipAddress, shipAddressDetail } = inputs;

  const updateInputData = e => {
    const { name, value } = e.target;

    setInputs({
      ...inputs,
      [name]: value,
    });
  };

  const resetInputData = () => {
    setInputs({
      loginId: "",
      password: "",
      confirmPwd: "",
      corpName: "",
      licenseNumber: "",
      corpRegNumber: "",
      address: "",
      addressDetail: "",
      repName: "",
      repPhone: "",
      corpPhone: "",
      refundBankName: "",
      refundBankOwner: "",
      refundBankAccount: "",
      managerName: "",
      managerDivision: "",
      managerPosition: "",
      managerPhone: "",
      managerEmail: "",
      taxEmail: "",
      shipAddress: "",
      shipAddressDetail: "",
    });
  };

  //* 은행 목록 추출 및 Selectbox 연동
  const [selectedValue, setSelectedValue] = useState("");
  const [bankList, setBankList] = useState();

  const loadOptions = () => {
    return fetchBankList();
  };

  //* 탭 3 selectbox 커스텀
  const customSelectboxStyles = useMemo(
    () => ({
      option: (provided, { isDisabled, isFocused, isSelected }) => ({
        ...provided,
        color: "black",
        opacity: 0.8,
        padding: 12,
        backgroundColor: isDisabled ? undefined : isSelected ? "#eaeaea" : isFocused ? "#eaeaea" : undefined,
        ":active": {
          ...provided[":active"],
          backgroundColor: "eaeaea",
        },
      }),
      control: (provided, state) => ({
        ...provided,
        width: "360px",
        height: "48px",
        background: "white",
        boxShadow: "transparent",
        borderColor: state.isFocused ? "#eaeaea" : "#eaeaea",
        "&:hover": {
          borderColor: state.isFocused ? "#eaeaea" : "#eaeaea",
        },
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#000",
      }),
    }),
    []
  );

  //* 탭 3 input[type=file] 세팅
  const [filename, setFilename] = useState("파일 업로드");
  const [imgSrc, setImgSrc] = useState("");

  const resetFileData = e => {
    if (filename == "파일 업로드") {
      return;
    }
    setFilename("파일 업로드");
    e.preventDefault();
  };

  // 파일 객체 설정
  const [imgBase64, setImgBase64] = useState([]); // 파일 base64
  const [imgFile, setImgFile] = useState(null); //파일

  const handleChangeFile = event => {
    setImgFile(event.target.files);
    setImgBase64([]);

    for (var i = 0; i < event.target.files.length; i++) {
      if (event.target.files[i]) {
        let reader = new FileReader();
        reader.readAsDataURL(event.target.files[i]);
        reader.onloadend = () => {
          const base64 = reader.result;
          if (base64) {
            var base64Sub = base64.toString();

            setImgBase64(imgBase64 => [...imgBase64, base64Sub]);
          }
        };
      }
    }

    let filename = event.target.files[0].name;

    setFilename(filename);

    if (event.target.files.length) {
      var imgTarget = event.target.files[0];
      var fileReader = new FileReader();
      fileReader.readAsDataURL(imgTarget);
      fileReader.onload = function (e) {
        setImgSrc(e.target.result);
      };
    } else {
      setImgSrc("");
    }
  };

  //* 주소 검색 팝업 데이터1
  const [addressPopup, setAddressPopup] = useState(false);
  const [daumAddress, setDaumAddress] = useState("");
  const [addressPost, setAddressPost] = useState("");
  const [daumData, setDaumData] = useState();

  // 팝업창 열기
  const openPostCode = () => {
    setAddressPopup(true);
  };
  // 팝업창 닫기
  const closePostCode = () => {
    setAddressPopup(false);
  };

  useEffect(() => {
    setInputs({
      ...inputs,
      ["address"]: daumAddress,
    });
  }, [daumAddress, addressPost]);

  //* 주소 검색 팝업 데이터2
  const [addressPopup2, setAddressPopup2] = useState(false);
  const [daumAddress2, setDaumAddress2] = useState("");
  const [shipAddressPost, setShipAddressPost] = useState("");
  const [daumData2, setDaumData2] = useState();

  // 팝업창 열기
  const openPostCode2 = () => {
    setAddressPopup2(true);
  };
  // 팝업창 닫기
  const closePostCode2 = () => {
    setAddressPopup2(false);
  };

  useEffect(() => {
    setInputs({
      ...inputs,
      ["shipAddress"]: daumAddress2,
    });
  }, [daumAddress2, shipAddressPost]);

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  // 이미지 객체 전송 / 이미지 ID값 return
  const fetchImageData = async () => {
    const fd = new FormData();
    Object.values(imgFile).forEach(file => fd.append("file", file));
    fd.append("comment", imgFile);

    const token = localStorage.getItem("access_token");

    const response = await axios.post(BASE_URL + UPLOAD_URL, fd, {
      headers: {
        "Content-Type": `multipart/form-data; `,
        Authorization: `Bearer ${token}`,
      },
    });

    return response;
  };

  // 가입 정보 formdata 전송
  const fetchFormData = async imageId => {
    try {
      const response = await axios.post(BASE_URL + JOIN_URL, {
        loginId: loginId,
        password: password,
        licenseImgId: imageId,
        corpName: corpName,
        licenseNumber: licenseNumber,
        corpRegNumber: corpRegNumber,
        address: address,
        addressDetail: addressDetail,
        repName: repName,
        repPhone: repPhone,
        corpPhone: corpPhone,
        refundBankName: refundBankName,
        refundBankOwner: refundBankOwner,
        refundBankAccount: refundBankAccount,
        managerName: managerName,
        managerDivision: managerDivision,
        managerPosition: managerPosition,
        managerPhone: managerPhone,
        managerEmail: managerEmail,
        taxEmail: managerEmail,
        deliveryLocations: [
          {
            address: shipAddress,
            addressDetail: shipAddressDetail,
            sido: daumData2.sido,
            sigungu: daumData2.sigungu,
            sigunguCode: daumData2.sigunguCode,
          },
        ],
      });

      return true;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 은행 목록 가져오기
  const fetchBankList = async () => {
    try {
      const response = await axios.get(BASE_URL + BANKLIST_URL);

      setBankList(response.data.data);
      return response.data.data;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };
  // const fetchBankList = async () => {
  //   let params = {
  //     id: null,
  //   };

  //   const response = await ApiController({
  //     url: BASE_URL + BANKLIST_URL,
  //     method: "get",
  //   });

  //   setBankList(response.data);
  //   return response.data;
  // };

  // ID 중복확인
  const fetchCheckId = async id => {
    try {
      const response = await axios.get(BASE_URL + IDCHECK_URL + `?login_id=${id}`);

      return true;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 사업자 등록번호, 법인 등록번호 중복확인
  const fetchCheckBusiness = async () => {
    try {
      const response = await axios.get(
        BASE_URL + BUSINESS_CHECK_URL + `?license_number=${licenseNumber}`
        // `?license_number=${licenseNumber}&corp_reg_number=${corpRegNumber}`
      );

      return true;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 담당자 연락처 중복확인
  const fetchCheckManager = async () => {
    try {
      const response = await axios.get(BASE_URL + MANAGER_CHECK_URL + `?manager_phone=${managerPhone}`);

      return true;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 인증번호 발송
  const fetchSend = async () => {
    try {
      const response = await axios.post(BASE_URL + AUTH_SEND_URL, {
        phone: managerPhone,
      });

      setFindDataId(response.data.data.id);
      setAuthNum(response.data.data.authNum);
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 인증번호 확인
  const fetchValidation = async authKeys => {
    try {
      const response = await axios.post(BASE_URL + AUTH_VALIDATION_URL, {
        id: findDataId,
        phone: managerPhone,
        authNum: managerPhoneAuth,
      });

      return true;
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  // 이용약관 내용 가져오기
  const fetchTerms = async () => {
    try {
      const response = await axios.get(BASE_URL + TERMS_URL);

      setTerms(response.data.data);
    } catch (err) {
      console.error(err.response?.data.message);
    }
  };

  /****************************************
   * TODO 유효성 검사
   ****************************************/
  //* 탭 1 이용약관 체크박스 유효성 검사
  const [terms, setTerms] = useState([]);
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);

  const allAgreeHandler = checked => {
    setIsAllChecked(!isAllChecked);
    if (checked) {
      setCheckedItems([...checkedItems, "provision", "privacy", "advertise"]);
    } else if ((!checked && checkedItems.includes("provision")) || (!checked && checkedItems.includes("privacy")) || (!checked && checkedItems.includes("advertise"))) {
      setCheckedItems([]);
    }
  };
  const agreeHandler = (checked, value) => {
    if (checked) {
      setCheckedItems([...checkedItems, value]);
    } else if (!checked && checkedItems.includes(value)) {
      setCheckedItems(checkedItems.filter(el => el !== value));
    }
  };

  useEffect(() => {
    // 탭 1 이용약관 선택
    if (tabIndex === 0) {
      if (checkedItems.includes("provision") && checkedItems.includes("privacy") && checkedItems.includes("advertise")) {
        setBtnState(true);
      } else {
        setBtnState(false);
        setAllowStep(1);
      }

      // 이용약관 selectbox 처리
      if (checkedItems.length >= 3) {
        setIsAllChecked(true);
      } else {
        setIsAllChecked(false);
      }
    }

    // 탭 2 유효성 검사
    if (tabIndex === 1) {
      if (loginId != "" && password != "" && confirmPwd != "") {
        setBtnState(true);

        if (allowStep >= 3) {
          // 아이디/비밀번호 유효성 검사
          if (loginId.length < 6) {
            setIdWarnStatus(false);
            setIdWarnTxt("최소 6글자 이상 입력해주세요.");
            setBtnState(false);
            setAllowStep(2);
            return;
          }
          if (password !== confirmPwd) {
            setPwWarnStatus(false);
            setPwWarnTxt("비밀번호가 일치하지 않습니다.");
            setBtnState(false);
            setAllowStep(2);
            return;
          }
          if (password.length < 8) {
            setPwWarnStatus(false);
            setPwWarnTxt("최소 8글자 이상 입력해주세요.");
            setBtnState(false);
            setAllowStep(2);
            return;
          }
          // if (checkId() === false) {
          //   setIdWarnStatus(false);
          //   setIdWarnTxt("아이디가 이미 존재합니다.");
          //   setBtnState(false);
          //   setAllowStep(2);
          //   return;
          // }
          setPwWarnStatus(true);
          setIdWarnStatus(true);
        }
      } else {
        setBtnState(false);
        setAllowStep(2);
      }

      // 아이디 확인
      if (idWarnStatus === false && loginId.length >= 6) {
        setIdWarnStatus(true);

        return;
      }
      // 비밀번호 확인
      if (pwWarnStatus === false && password.length >= 8 && confirmPwd.length >= 8) {
        if (password === confirmPwd) {
          setPwWarnStatus(true);
          return;
        }
      }
    }

    // 탭 3 유효성 검사
    if (tabIndex === 2) {
      if (filename != "파일 업로드" && corpName != "" && licenseNumber != "" && corpRegNumber != "" && address != "" && repName != "" && corpPhone != "" && refundBankOwner != "" && refundBankAccount != "") {
        setBtnState(true);

        if (allowStep >= 4) {
          if (licenseNumber.length !== 10) {
            setLicenseWarnStatus(false);
            setLicenseWarnTxt("사업자 등록번호를 확인해주세요.");
            setAllowStep(3);
            return;
          }
          if (corpRegNumber.length !== 13) {
            setCorpWarnStatus(false);
            setCorpWarnTxt("법인 등록번호를 확인해주세요.");
            setAllowStep(3);
            return;
          }
        }

        setLicenseWarnStatus(true);
        setCorpWarnStatus(true);
        // if (licenseWarnStatus === false && licenseNumber.length === 10) {
        //   setLicenseWarnStatus(true);
        //   setLicenseWarnTxt("");
        // }
        // if (corpWarnStatus === false && corpRegNumber.length === 13) {
        //   setCorpWarnStatus(true);
        //   setCorpWarnTxt("");
        // }
      } else {
        setBtnState(false);
        setAllowStep(3);
      }
    }

    // 탭 4 유효성 검사
    if (tabIndex === 3) {
      if (managerName != "" && managerPhone != "" && managerPhoneAuth != "" && managerEmail != "") {
        if (isManagerCheck === true && isManagerAuth === true) {
          setBtnState(true);
        } else {
          setBtnState(false);
        }
      } else {
        setBtnState(false);
        setAllowStep(4);
      }
    }

    // 탭 5 유효성 검사
    if (tabIndex === 4) {
      if (taxEmail != "" && shipAddress != "") {
        setBtnState(true);
      } else {
        setBtnState(false);
        setAllowStep(5);
      }
    }
  }, [
    tabIndex,
    checkedItems,
    loginId,
    password,
    confirmPwd,
    filename,
    corpName,
    licenseNumber,
    corpRegNumber,
    address,
    addressDetail,
    repName,
    repPhone,
    corpPhone,
    refundBankOwner,
    refundBankAccount,
    managerName,
    managerDivision,
    managerPosition,
    managerPhone,
    managerPhoneAuth,
    managerEmail,
    taxEmail,
    shipAddress,
    shipAddressDetail,
    loginId,
    idWarnStatus,
    password,
    confirmPwd,
    pwWarnStatus,
    isManagerCheck,
    isManagerAuth,
  ]);

  // 담당자 연락처 인증하기
  const handleCheckManagerPhone = async () => {
    if (managerPhone.length === 0) {
      handleToast("연락처를 입력해주세요.");
      return;
    }
    if (managerPhone.length <= 10) {
      handleToast("담당자 연락처를 확인해주세요.");
      return;
    }
    if ((await fetchCheckManager()) === false) {
      setIsManagerCheck(false);
      return;
    }
    //TODO phone에다가 인증번호 날리기
    fetchSend();
    setIsManagerCheck(true);
  };
  // 담당자 연락처 인증번호 확인
  const handleCheckManagerAuth = async () => {
    //TODO 인증확인시에 setIsManagerAuth(true) 처리
    if ((await fetchValidation()) === false) {
      setIsManagerAuth(false);
      return;
    }
    setIsManagerAuth(true);
    handleToast("인증되었습니다.");
  };

  // 아이디 입력창 onBlur
  const checkId = async () => {
    if (loginId != "") {
      const result = await fetchCheckId(loginId);
      return result;
    }
  };

  const maxLengthCheck = object => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(0, object.target.maxLength);
    }
  };
  /****************************************
   * TODO 모달 & 팝업
   ****************************************/
  const [modalState, setModalState] = useState(false);
  const [modalContent, setModalContent] = useState();

  const switchTermsContents = idx => {
    const serviceTxt = terms.find(term => {
      return term.termType === "SERVICE";
    });
    const personalTxt = terms.find(term => {
      return term.termType === "PERSONAL";
    });
    const notificationTxt = terms.find(term => {
      return term.termType === "NOTIFICATION";
    });

    if (idx === 1) {
      setModalContent(serviceTxt.content);
    } else if (idx === 2) {
      setModalContent(personalTxt.content);
    } else if (idx === 3) {
      setModalContent(notificationTxt.content);
    }
    setModalState(true);
  };

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = txt => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      <div className={`${styles.join}` + " form-width"}>
        <div className={styles.join__header}>
          <h1 className="heading-1">회원가입</h1>
          <ProgressBar percent={progressValue} />
        </div>
        {/********** TAB 1 : 이용약관 동의 **********/}
        <div className={styles.tabs}>
          <div className={allowStep >= 1 ? `${styles.tab}` + ` ${styles.active}` : `${styles.tab}`} onClick={() => setTabIndex(0)}>
            <span>1</span>
          </div>
          <div className={allowStep >= 2 ? `${styles.tab}` + ` ${styles.active}` : `${styles.tab}`} onClick={() => setTabIndex(1)}>
            <span>2</span>
          </div>
          <div className={allowStep >= 3 ? `${styles.tab}` + ` ${styles.active}` : `${styles.tab}`} onClick={() => setTabIndex(2)}>
            <span>3</span>
          </div>
          <div className={allowStep >= 4 ? `${styles.tab}` + ` ${styles.active}` : `${styles.tab}`} onClick={() => setTabIndex(3)}>
            <span>4</span>
          </div>
          <div className={allowStep >= 5 ? `${styles.tab}` + ` ${styles.active}` : `${styles.tab}`} onClick={() => setTabIndex(4)}>
            <span>5</span>
          </div>
        </div>
        <div className={tabIndex == 0 ? `${styles.join__content} ${styles.join__content__visible}` : `${styles.join__content}`}>
          <div className={`${styles.join__content__mainTxt}` + " heading-3"}>이용약관</div>
          <p className={`${styles.join__content__subTxt}` + " pm"}>이용 약관을 확인하고 동의해주세요.</p>
          <div className={styles.join__content__termsBox}>
            <div className={`${styles.join__content__termsBox__all}` + " checkbox"}>
              <label>
                <input type="checkbox" value="agree" onChange={e => allAgreeHandler(e.currentTarget.checked, e.target.value)} checked={isAllChecked} />
                <div></div>
                <span>전체 동의</span>
              </label>
            </div>
            <div className={`${styles.join__content__termsBox__normal}` + " checkbox"}>
              <label>
                <input type="checkbox" value="provision" onChange={e => agreeHandler(e.currentTarget.checked, e.target.value)} checked={checkedItems.includes("provision") ? true : false} />
                <div></div>
                <span>(필수) 서비스 이용약관</span>
              </label>
              <button
                type="button"
                className="btn-read"
                onClick={() => {
                  switchTermsContents(1);
                }}
              >
                보기
              </button>
            </div>
            <div className={`${styles.join__content__termsBox__normal}` + " checkbox"}>
              <label>
                <input type="checkbox" value="privacy" onChange={e => agreeHandler(e.currentTarget.checked, e.target.value)} checked={checkedItems.includes("privacy") ? true : false} />
                <div></div>
                <span>(필수) 개인정보 수집∙이용 동의</span>
              </label>
              <button
                type="button"
                className="btn-read"
                onClick={() => {
                  switchTermsContents(2);
                }}
              >
                보기
              </button>
            </div>
            <div className={`${styles.join__content__termsBox__normal}` + " checkbox"}>
              <label>
                <input type="checkbox" value="advertise" onChange={e => agreeHandler(e.currentTarget.checked, e.target.value)} checked={checkedItems.includes("advertise") ? true : false} />
                <div></div>
                <span>(필수) 구매 진행 알림톡 수신 동의 </span>
              </label>
              <button
                type="button"
                className="btn-read"
                onClick={() => {
                  switchTermsContents(3);
                }}
              >
                보기
              </button>
            </div>
          </div>
        </div>
        {/* 모달 */}
        {modalState ? <Modal_info modalState={setModalState} dim={false} title="이용약관" content={modalContent} /> : null}

        {/********** TAB 2 : 아이디&비밀번호 입력 **********/}
        <div className={tabIndex == 1 ? `${styles.join__content} ${styles.join__content__visible}` : `${styles.join__content}`}>
          <div className={`${styles.join__content__mainTxt}` + " heading-3"}>아이디&amp;비밀번호</div>
          <p className={`${styles.join__content__subTxt}` + " pm"}>로그인에 사용할 아이디와 비밀번호를 입력해주세요.</p>
          <div className={styles.join__content__formContainer}>
            {/* 아이디 */}
            <div className={idWarnStatus === true ? "input-form" : "input-form error"}>
              <p className="form-title">
                <span>아이디</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="text"
                    name="loginId"
                    className=""
                    placeholder="아이디 입력"
                    onKeyDown={e => {
                      if (e.keyCode === 32) {
                        // spacebar 막기
                        e.preventDefault();
                      }
                    }}
                    onChange={updateInputData}
                    value={loginId}
                    // onBlur={checkId}
                  />
                </div>
              </div>
              <p className="alert-msg error">{idWarnTxt}</p>
            </div>
            {/* 패스워드 */}
            <div className={pwWarnStatus === true ? "input-form" : "input-form error"}>
              <p className="form-title">
                <span>비밀번호</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="password"
                    name="password"
                    className=""
                    placeholder="비밀번호 입력"
                    onKeyDown={e => {
                      if (e.keyCode === 32) {
                        // spacebar 막기
                        e.preventDefault();
                      }
                    }}
                    onChange={updateInputData}
                    value={password}
                  />
                </div>
              </div>
            </div>
            <div className={pwWarnStatus === true ? "input-form" : "input-form error"}>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="password"
                    name="confirmPwd"
                    className=""
                    placeholder="비밀번호 재입력"
                    onKeyDown={e => {
                      if (e.keyCode === 32) {
                        // spacebar 막기
                        e.preventDefault();
                      }
                    }}
                    onChange={updateInputData}
                    value={confirmPwd}
                  />
                </div>
              </div>
              <p className="alert-msg error">{pwWarnTxt}</p>
            </div>
          </div>
        </div>

        {/********** TAB 3 : 법인 정보 입력 **********/}
        <div className={tabIndex == 2 ? `${styles.join__content} ${styles.join__content__visible}` : `${styles.join__content}`}>
          <div className={`${styles.join__content__mainTxt}` + " heading-3"}>법인 정보</div>
          <p className={`${styles.join__content__subTxt}` + " pm"}>서비스 이용을 위한 법인정보를 입력하세요. 사업자등록번호 중복 시 가입이 불가합니다.</p>
          <div className={styles.join__content__formContainer}>
            {/* 사업자 등록증 */}
            <div className="input-form required">
              <p className="form-title">
                <span>사업자 등록증</span>
              </p>
              <div className="form-field">
                <div className="file-element">
                  <span className="upload_name">{filename}</span>
                  <label htmlFor="file">
                    <img src={filename == "파일 업로드" ? fileUploadIcon : fileDeleteIcon} alt="" onClick={resetFileData} />
                  </label>
                  <input type="file" id="file" onChange={handleChangeFile} />
                </div>
              </div>
              <div className="form-image">
                {filename != null && filename != "파일 업로드" ? (
                  <div className="image-item">
                    <img src={imgSrc} />
                  </div>
                ) : (
                  <div className="image-empty"></div>
                )}
              </div>
            </div>
            {/* 법인명 */}
            <div className="input-form required">
              <p className="form-title">
                <span>법인명</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input type="text" name="corpName" className="" placeholder="법인명 입력" onChange={updateInputData} value={corpName} />
                </div>
              </div>
            </div>
            {/* 사업자 등록번호 */}
            <div className={licenseWarnStatus === true ? "input-form required" : "input-form required error"}>
              <p className="form-title">
                <span>사업자 등록번호</span>
                <em className="title-sub">(-없이 입력하세요)</em>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input type="number" name="licenseNumber" className="" placeholder="- 없이 입력" onChange={updateInputData} value={licenseNumber} onInput={maxLengthCheck} maxLength="10" />
                </div>
              </div>
              <p className="alert-msg error">{licenseWarnTxt}</p>
            </div>
            {/* 법인 등록번호 */}
            <div className={corpWarnStatus === true ? "input-form required" : "input-form required error"}>
              <p className="form-title">
                <span>법인 등록번호</span>
                <em className="title-sub">(-없이 입력하세요)</em>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input type="number" name="corpRegNumber" className="" placeholder="- 없이 입력" onChange={updateInputData} value={corpRegNumber} onInput={maxLengthCheck} maxLength="13" />
                </div>
              </div>
              <p className="alert-msg error">{corpWarnTxt}</p>
            </div>
            {/* 사업자 주소 */}
            <div className="input-form required">
              <p className="form-title">
                <span>사업자 주소</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input type="text" name="address" className="" placeholder="주소" onChange={updateInputData} value={address} readOnly />
                </div>
                <button type="button" className="btn-address" onClick={openPostCode}>
                  주소 검색
                </button>
              </div>
            </div>

            {/* 팝업 */}
            {addressPopup && <DaumPost onClose={closePostCode} setAddress={setDaumAddress} setAddressPost={setAddressPost} setDaumData={setDaumData} />}

            <div className="input-form">
              <div className="form-field">
                <div className="input-element">
                  <input type="text" name="addressDetail" className="" placeholder="상세 주소 입력" onChange={updateInputData} value={addressDetail} />
                </div>
              </div>
            </div>
            {/* 대표자 성함 */}
            <div className="input-form required">
              <p className="form-title">
                <span>대표자 성함</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="text"
                    name="repName"
                    className=""
                    placeholder="대표자 성함 입력 (한글 입력)"
                    onKeyPress={e => {
                      if (e.keyCode < 12592 || e.keyCode > 12687) {
                        e.returnValue = false;
                        e.preventDefault();
                      }
                    }}
                    onChange={updateInputData}
                    value={repName}
                  />
                </div>
              </div>
            </div>
            {/* 대표자 연락처 */}
            <div className="input-form">
              <p className="form-title">
                <span>대표자 연락처</span>
                <em className="title-sub">(-없이 입력하세요)</em>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input type="number" name="repPhone" className="" placeholder="- 없이 입력" onChange={updateInputData} value={repPhone} onInput={maxLengthCheck} maxLength="11" />
                </div>
              </div>
            </div>
            {/* 대표 번호 */}
            <div className="input-form required">
              <p className="form-title">
                <span>대표 번호</span>
                <em className="title-sub">(-없이 입력하세요)</em>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input type="number" name="corpPhone" className="" placeholder="- 없이 입력" onChange={updateInputData} value={corpPhone} onInput={maxLengthCheck} maxLength="11" />
                </div>
              </div>
            </div>
            {/* 환불 계좌 정보 */}
            <div className="input-form required">
              <p className="form-title">
                <span>환불 계좌 정보</span>
              </p>
              <div className="form-field">
                <div className="select-element">
                  <AsyncSelect
                    cacheOptions
                    defaultOptions
                    loadOptions={loadOptions}
                    getOptionLabel={e => e.name}
                    getOptionValue={e => e.id}
                    value={selectedValue}
                    onChange={e => {
                      setInputs({
                        ...inputs,
                        ["refundBankName"]: e.name,
                      });
                      setSelectedValue(e.value);
                    }}
                    // options={bankList}
                    // defaultValue={options[0]}
                    isClearable={false}
                    isSearchable={false}
                    closeMenuOnSelect={true}
                    styles={customSelectboxStyles}
                    placeholder="은행 선택"
                  />
                </div>
              </div>
            </div>
            <div className="input-form required">
              <div className="form-field">
                <div className="input-element">
                  <input type="text" name="refundBankAccount" className="" placeholder="계좌번호 - 없이 입력" onChange={updateInputData} value={refundBankAccount} />
                </div>
              </div>
            </div>
            <div className="input-form required">
              <div className="form-field">
                <div className="input-element">
                  <input type="text" name="refundBankOwner" className="" placeholder="예금주" onChange={updateInputData} value={refundBankOwner} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/********** TAB 4 : 담당자 정보 입력 **********/}
        <div className={tabIndex == 3 ? `${styles.join__content} ${styles.join__content__visible}` : `${styles.join__content}`}>
          <div className={`${styles.join__content__mainTxt}` + " heading-3"}>담당자 정보</div>
          <p className={`${styles.join__content__subTxt}` + " pm"}>서비스 이용을 위한 법인정보를 입력하세요.</p>
          <div className={styles.join__content__formContainer}>
            {/* 담당자 성함 */}
            <div className="input-form required">
              <p className="form-title">
                <span>담당자 성함</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input
                    type="text"
                    name="managerName"
                    className=""
                    placeholder="담당자 성함 입력 (한글 입력)"
                    onKeyPress={e => {
                      if (e.keyCode < 12592 || e.keyCode > 12687) {
                        e.returnValue = false;
                        e.preventDefault();
                      }
                    }}
                    onChange={updateInputData}
                    value={managerName}
                  />
                </div>
              </div>
            </div>
            {/* 담당 부서 */}
            <div className="input-form">
              <p className="form-title">
                <span>담당 부서</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input type="text" name="managerDivision" className="" placeholder="담당 부서 입력" onChange={updateInputData} value={managerDivision} />
                </div>
              </div>
            </div>
            {/* 직급 */}
            <div className="input-form">
              <p className="form-title">
                <span>직급</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input type="text" name="managerPosition" className="" placeholder="직급 입력" onChange={updateInputData} value={managerPosition} />
                </div>
              </div>
            </div>
            {/* 담당자 연락처 */}
            <div className="input-form required">
              <p className="form-title">
                <span>담당자 연락처</span>
                <em className="title-sub">(-없이 입력하세요)</em>
              </p>
              <div className="form-field disabled">
                <div className="input-element">
                  <input type="number" name="managerPhone" className="" placeholder="- 없이 입력" onChange={updateInputData} value={managerPhone} onInput={maxLengthCheck} maxLength="11" />
                </div>
                <button type="button" className="btn-auth" onClick={handleCheckManagerPhone}>
                  {isManagerCheck === false ? "인증하기" : "인증번호 재전송"}
                </button>
              </div>
            </div>
            {isManagerCheck === true && (
              <div className="input-form required">
                <div className="form-field">
                  <div className="input-element">
                    <input type="text" name="managerPhoneAuth" className="" placeholder="인증번호 입력" onChange={updateInputData} value={managerPhoneAuth} />
                  </div>
                  <button type="button" className="btn-auth" onClick={handleCheckManagerAuth}>
                    인증확인
                  </button>
                </div>
              </div>
            )}
            {/* 담당자 이메일 */}
            <div className="input-form required">
              <p className="form-title">
                <span>담당자 이메일</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input type="text" name="managerEmail" className="" placeholder="예시) Mobiment@gmail.com" onChange={updateInputData} value={managerEmail} />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/********** TAB 5 : 구매 정보 입력 **********/}
        <div className={tabIndex == 4 ? `${styles.join__content} ${styles.join__content__visible}` : `${styles.join__content}`}>
          <div className={`${styles.join__content__mainTxt}` + " heading-3"}>구매 정보</div>
          <p className={`${styles.join__content__subTxt}` + " pm"}>구매를 위해 필요한 정보를 입력해주세요.</p>
          <div className={styles.join__content__formContainer}>
            {/* 세금 계산서 발행 이메일 */}
            <div className="input-form required">
              <p className="form-title">
                <span>세금 계산서 발행 이메일</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input type="text" name="taxEmail" className="" placeholder="예시) Mobiment@gmail.com" onChange={updateInputData} value={taxEmail} />
                </div>
              </div>
            </div>
            {/* 배송지 정보 */}
            <div className="input-form required">
              <p className="form-title">
                <span>배송지 정보</span>
              </p>
              <div className="form-field">
                <div className="input-element">
                  <input type="text" name="shipAddress" className="" placeholder="주소" onChange={updateInputData} value={shipAddress} readOnly />
                </div>
                <button type="button" className="btn-address" onClick={openPostCode2}>
                  주소 검색
                </button>
              </div>
            </div>
            <div className="input-form">
              <div className="form-field">
                <div className="input-element">
                  <input type="text" name="shipAddressDetail" className="" placeholder="상세 주소 입력" onChange={updateInputData} value={shipAddressDetail} />
                </div>
              </div>
            </div>
            {/* 팝업 */}
            {addressPopup2 && <DaumPost onClose={closePostCode2} setAddress={setDaumAddress2} setAddressPost={setShipAddressPost} setDaumData={setDaumData2} />}
          </div>
        </div>

        <div className={`${styles.join__buttons}` + " button-form"}>
          <button type="button" className={btnState ? "btn btn-xl btn--primary" : "btn btn-xl btn--disabled"} onClick={() => handleProgressBar()} disabled={!btnState}>
            다음
          </button>
        </div>
      </div>

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />
    </>
  );
};

export default Join;
