/* eslint-disable */
import React, { useEffect, useState, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import axios from "axios";
import allActions from "../actions";

import NavList from "../redux/NavList.js";
import * as Common from "../actions/Common.js";
import Toast from "../components/Toast.js";
import ModalConfirm from "../components/ModalConfirm.js";
import ApiGetMe from "../api/ApiGetMe";

import DeleteIcon from "../assets/icons/close-gr.svg";
import SearchIcon from "../assets/icons/search-pr.svg";
import LogoIcon from "../assets/icons/icon-mobilicar-logo_bk.png";
import NotiIcon from "../assets/icons/noti.svg";
import PersonGrIcon from "../assets/icons/person-gr.svg";
import PersonPrIcon from "../assets/icons/person-pr.svg";
import depthIcon from "../assets/icons/depth-sm-gr.svg";
import sampleImg from "../assets/images/87302-4.png";

import styles from "./Header.module.scss";

// API URL
import { baseUrl } from "../api";
const BASE_URL = baseUrl();
const MYDATA_URL = "/api/user/member/v1/me";
const SEARCH_URL = `/api/user/search/v1/car`;
const CARMODEL_URL = `/api/user/car/v1/car_model`;
const NOTIFICATION_URL = "/api/user/notification/v1";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  /****************************************
   * TODO 초기 세팅
   ****************************************/
  //* 로그인 상태 확인 및 재설정
  const userStatus = useSelector((state) => state.currentUser);
  const [loginStatus, setLoginStatus] = useState(userStatus.login);
  // const loginStatus = userStatus.login;

  useEffect(() => {
    initKeywords();
  }, []);

  const checkLoginStatus = async () => {
    const result = await fetchMyData();
    if (result === 200) {
      setLoginStatus(true);
    } else {
      setLoginStatus(false);

      // 로그아웃 처리
      localStorage.removeItem("access_token");
      Common.delCookie("chada_login_status");
      dispatch(allActions.userActions.logoutUser());
    }
  };

  useEffect(() => {
    if (loginStatus === true) {
      fetchNotification();
    }
  }, [loginStatus]);

  //* 현재 페이지 확인
  const [menuPath, setMenuPath] = useState("");

  const menuToggleHandler = (path) => {
    setMenuPath(path);
  };

  useEffect(() => {
    let path = location.pathname.split("/")[1];
    menuToggleHandler(path);
    checkLoginStatus();
  }, [location]);

  /****************************************
   * TODO 모델명 검색 SEARCH INPUT
   ****************************************/
  //* Init Keyword
  const [myInfo, setMyInfo] = useState();
  const [optionStatus, setOptionStatus] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [searchList, setSearchList] = useState([]);

  // 키워드 세팅
  const initKeywords = () => {
    const cookieResult = Common.getCookieJson("chada_search_list");
    setSearchList(cookieResult);
  };

  // 키워드 제거
  const deleteKeywords = (keyword) => {
    let cookieList = Common.getCookieJson("chada_search_list");
    let deleteIdx = 0;

    if (cookieList && cookieList.length != 0) {
      cookieList.find((e, idx) => {
        if (e.nameKr === keyword) {
          deleteIdx = idx;
        }
      });

      cookieList.splice(deleteIdx, 1);
    }
    Common.setCookie("chada_search_list", JSON.stringify(cookieList), 7);
    // setState
    initKeywords();
  };

  // 검색 버튼 클릭 / 결과 페이지로 이동
  const showResult = () => {
    setKeywordCookie(searchValue);
    initKeywords();
    navigate("/buy", { state: { keyword: searchValue } });
    // dispatch(allActions.userActions.loginUser());
  };
  const showResultByChoice = (value) => {
    setSearchValue(value);
    setOptionStatus(false);
    setKeywordCookie(value);
    initKeywords();
    navigate("/buy", { state: { keyword: value } });
  };

  //* 검색 결과(keyword) 쿠키 저장
  const setKeywordCookie = (keyword) => {
    if (keyword === "") {
      return;
    }
    let cookieList = [];
    const newCookie = {
      viewedAt: Common.getCurrentTime(),
      nameKr: keyword,
    };

    if (Common.getCookieJson("chada_search_list") != null) {
      cookieList = Common.getCookieJson("chada_search_list");
    }
    // 쿠키에 같은 키워드가 있는지 확인
    let checkCookieKeyword = true;
    if (cookieList && cookieList.length != 0) {
      cookieList.find((e) => {
        if (e.nameKr === keyword) {
          checkCookieKeyword = false;
        }
      });
    }

    // 중복 키워드가 없는 경우 쿠키 새로 저장
    if (checkCookieKeyword === true) {
      if (cookieList.length >= 20) {
        cookieList.shift();
      }
      cookieList.push(newCookie);
      Common.setCookie("chada_search_list", JSON.stringify(cookieList), 7);
    }
  };

  const handleSearch = async (e) => {
    setSearchValue(e.target.value);
    if (e.target.value === "") return;

    const result = await fetchSearchData(e.target.value); // input value로 비동기 통신 진행,

    const newArr = result.filter((data) => {
      return (
        data.keyword?.toLowerCase().search(e.target.value.toLowerCase()) != -1
      );
    });

    setFilteredData(newArr);
  };

  // Select Option 외부 클릭시 레이어 숨김
  const useOptionCloser = (ref) => {
    const handleClickOutside = (e) => {
      if (ref.current && !ref.current.contains(e.target)) {
        setOptionStatus(false);
      }
    };

    useEffect(() => {
      document.addEventListener("mousedown", handleClickOutside);

      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    });
  };

  const optionsRef = useRef(null);
  useOptionCloser(optionsRef);

  //* 우측 util 버튼 init
  const [alert, setAlert] = useState(false);
  const [mypage, setMypage] = useState(false);
  // 알림내역
  const [notifications, setNotifications] = useState([]);

  /****************************************
   * TODO 항목별 API 목록
   ****************************************/
  const fetchMyData = async () => {
    try {
      const response = await axios.get(BASE_URL + MYDATA_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setMyInfo(response.data.data);
      return response.status;
    } catch (err) {
      console.error(err.response?.data.message);
      return false;
    }
  };
  // 모델명 키워드 검색
  const fetchSearchData = async (keyword) => {
    try {
      const urlParams = `?searchKeyWord=${keyword}`;
      // const urlParams = `?car_model_name=${keyword}`;

      const response = await axios.get(BASE_URL + SEARCH_URL + urlParams, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      return response.data.data;
    } catch (err) {
      handleToast(err.response?.data.message);
    }
  };
  // 알림 내역 데이터 가져오기
  const fetchNotification = async () => {
    try {
      const response = await axios.get(BASE_URL + NOTIFICATION_URL, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      });

      setNotifications(response.data.data);
    } catch (err) {
      handleToast(err.response?.data.message);
      return false;
    }
  };

  /****************************************
   * TODO 모달 & 토스트 팝업 상태
   ****************************************/
  //* 모달
  const [modal, setModal] = useState({
    open: false,
    dim: true,
    title: "",
    message: "",
    notxt: "",
    oktxt: "확인",
    callback1: false,
    callback2: false,
  });

  //* 토스트 팝업 세팅
  const [toastStatus, setToastStatus] = useState(false);
  const [toastMsg, setToastMsg] = useState(""); // 토스트 메세지

  const handleToast = (txt) => {
    setToastStatus(true);
    setToastMsg(txt);
  };
  useEffect(() => {
    if (toastStatus) {
      setTimeout(() => {
        setToastStatus(false);
        setToastMsg("");
      }, 1000);
    }
  }, [toastStatus]);

  return (
    <>
      <header className={styles.header}>
        <div className={styles.content}>
          {/* GNB */}
          <div className={styles.gnb}>
            <Link to="/" className={styles.logo}>
              <img src={LogoIcon} alt="" />
            </Link>

            <nav className={styles.nav}>
              <ul>
                {NavList.map(({ title, path }) => {
                  return (
                    <li
                      key={`${path}`}
                      className={`${path}` == menuPath ? styles.active : ""}
                    >
                      <Link to={`/${path}`}>{`${title}`}</Link>
                    </li>
                  );
                })}
              </ul>
            </nav>
          </div>

          {/* 서비스 항목 */}
          <div
            className={styles.service}
            onMouseLeave={() => {
              setAlert(false);
              setMypage(false);
            }}
          >
            <div className={styles.searchForm} ref={optionsRef}>
              <div className={styles.searchEle}>
                <input
                  type="text"
                  placeholder="모델명 또는 차량번호를 입력하세요"
                  value={searchValue}
                  onFocus={(e) => {
                    handleSearch(e);
                    setOptionStatus(true);
                  }}
                  onChange={(e) => {
                    handleSearch(e);
                  }}
                  onKeyPress={(e) => {
                    if (e.key === "Enter") {
                      showResult();
                    }
                  }}
                />

                <button type="button" onClick={showResult}>
                  <img src={SearchIcon} alt="" />
                </button>
              </div>
              {optionStatus ? (
                <div className={styles.searchOptions}>
                  <div className={styles.optionHeader}>
                    {searchValue === ""
                      ? searchList !== null && "최근 검색"
                      : filteredData.length === 0
                      ? "검색 결과가 없습니다."
                      : "검색 결과"}
                  </div>
                  <div className={styles.optionList}>
                    {searchValue === "" && searchList !== null
                      ? searchList.map((item, index) => {
                          return (
                            // 최근 검색
                            <div className={styles.optionItem} key={index}>
                              <span
                                className={styles.itemTit}
                                onClick={() => {
                                  showResultByChoice(item.nameKr);
                                }}
                              >
                                {item.nameKr}
                              </span>
                              <button
                                className={styles.btnRemove}
                                onClick={() => {
                                  deleteKeywords(item.nameKr);
                                }}
                              >
                                <img src={DeleteIcon} alt="" />
                              </button>
                            </div>
                          );
                        })
                      : filteredData.map((item, index) => {
                          return (
                            // 검색 결과
                            <div className={styles.optionItem} key={index}>
                              <span
                                className={styles.itemTit}
                                onClick={() => {
                                  showResultByChoice(item.nameKr);
                                }}
                              >
                                {item.nameKr}
                              </span>
                            </div>
                          );
                        })}
                  </div>
                </div>
              ) : null}
            </div>
            <div className={styles.util}>
              {loginStatus && (
                <button
                  type="button"
                  className={styles.alert}
                  onMouseEnter={() => {
                    setMypage(false);
                    setAlert(true);
                  }}
                >
                  <img src={NotiIcon} alt="" />
                </button>
              )}
              <button
                type="button"
                className={styles.mypage}
                onMouseEnter={() => {
                  setAlert(false);
                  setMypage(true);
                }}
              >
                {loginStatus ? (
                  <img src={PersonPrIcon} alt="" />
                ) : (
                  <img src={PersonGrIcon} alt="" />
                )}
              </button>
            </div>

            {/* 알람 모달 */}
            {alert ? (
              <div className={styles.modalAlert}>
                {notifications.length === 0 ? (
                  <div className={styles.itemList}>
                    <div className={styles.item}>
                      <div className={styles.noAlerts}>
                        신규 알림이 없습니다.
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <div className={styles.itemList}>
                      {notifications.map((item, idx) => {
                        if (idx < 5)
                          return (
                            <div
                              className={styles.item}
                              onClick={() => {
                                navigate("/mypage", {
                                  state: {
                                    tabIndex: 9,
                                  },
                                });
                              }}
                            >
                              {/* <div className={styles.img}>
                                <img src={item.imgUrl} alt="" />
                              </div> */}
                              <div className={styles.desc}>
                                <div className={styles.note}>
                                  {item.content}
                                </div>
                                <div className={styles.date}>
                                  {item.createdAt.split("T")[0]}
                                </div>
                              </div>
                            </div>
                          );
                      })}
                    </div>
                    <a
                      className={styles.more}
                      onClick={() => {
                        navigate("/mypage", {
                          state: {
                            tabIndex: 9,
                          },
                        });
                      }}
                    >
                      알림 내역 더보기 <img src={depthIcon} alt="" />
                    </a>
                  </>
                )}
              </div>
            ) : null}
            {/* 마이페이지 모달 */}
            {mypage ? (
              loginStatus ? (
                <div className={styles.modalMypage}>
                  <Link to={"/mypage"}>마이페이지</Link>
                  <Link to={"/logout"}>로그아웃</Link>
                  {/* <a
                  onClick={() => {
                    localStorage.removeItem("access_token");
                    delCookie("chada_login_status");
                    dispatch(allActions.userActions.logoutUser());
                    window.location.reload();
                  }}
                >
                  로그아웃
                </a> */}
                </div>
              ) : (
                <div className={styles.modalMypage}>
                  <Link to={"/login"}>로그인</Link>
                  <Link to={"/join"}>회원가입</Link>
                </div>
              )
            ) : null}
          </div>
        </div>
      </header>

      {/* 토스트 팝업 */}
      <Toast visible={toastStatus} msg={toastMsg} />

      {/* 로그인 모달 */}
      {modal.open && (
        <ModalConfirm
          dim={modal.dim}
          setModal={setModal}
          title={modal.title}
          message={modal.message}
          notxt={modal.notxt}
          oktxt={modal.oktxt}
          callback1={modal.callback1}
          callback2={modal.callback2}
        />
      )}
    </>
  );
};

export default Header;
